import {
    Box,
    Button,
    CachedIcon,
    enerbitColors,
    InputLabel,
    MenuItem,
    SearchIcon,
    Typography,
} from "@enerbit/base";
import CustomSelect from "../../../components/Inputs/custom-select";
import CustomTextField from "../../../components/Inputs/custom-textfield";
import { useMetersState } from "../../../store";
import { ObjectKeyValue } from "@enerbit/base/common/models/customPropsComponents";

interface Props {
    filters: ObjectKeyValue;
    handleSearch: () => void;
    handleReset: () => void;
    handleFilterChange: (key: string, value: string) => void;
}

const Filters = ({
    filters,
    handleFilterChange,
    handleReset,
    handleSearch,
}: Props) => {
    const { metetModels, loading } = useMetersState((state) => state);

    return (
        <Box
            sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
            }}
        >
            <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                <Box>
                    <InputLabel sx={{ mb: 1 }}>Serial</InputLabel>
                    <CustomTextField
                        fullWidth
                        sx={{ width: "295px" }}
                        placeholder="Buscar por serial"
                        value={filters["meter_serial"]}
                        onChange={(e) => {
                            handleFilterChange(
                                "meter_serial",
                                e.target.value as string,
                            );
                        }}
                        InputProps={{
                            startAdornment: (
                                <SearchIcon
                                    sx={{
                                        color: enerbitColors.neutral[700],
                                        fontSize: "12",
                                        mr: 1,
                                    }}
                                />
                            ),
                        }}
                    />
                </Box>
                <Box>
                    <InputLabel sx={{ mb: 1 }}>Modelo</InputLabel>
                    <CustomSelect
                        displayEmpty
                        fullWidth
                        sx={{ width: "295px" }}
                        defaultValue={""}
                        placeholder="Seleccionar modelo"
                        value={filters["model_id"]}
                        onChange={(e) => {
                            handleFilterChange(
                                "model_id",
                                e.target.value as string,
                            );
                        }}
                        disabled={loading}
                    >
                        <MenuItem disabled value="">
                            <Typography sx={{ color: "rgba(0, 0, 0, 0.42)" }}>
                                Seleccionar modelo
                            </Typography>
                        </MenuItem>
                        {metetModels.map((model) => (
                            <MenuItem key={model.id} value={model.id}>
                                {model.name}
                            </MenuItem>
                        ))}
                    </CustomSelect>
                </Box>
                <Box>
                    <Button
                        variant="contained"
                        color="primary"
                        sx={{ mt: 3 }}
                        onClick={handleSearch}
                    >
                        <SearchIcon />
                    </Button>
                </Box>
                <Box>
                    <Button variant="text" sx={{ mt: 3 }} onClick={handleReset}>
                        <CachedIcon />
                    </Button>
                </Box>
            </Box>
        </Box>
    );
};

export default Filters;

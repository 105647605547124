import { Controller, useForm } from "react-hook-form";
import StyledModal from "../../../components/container/styled-modal";
import {
    AdapterMoment,
    CustomAlert,
    DateTimePicker,
    FormControl,
    Grid,
    InputLabel,
    LoadingButton,
    LocalizationProvider,
} from "@enerbit/base";
import { DATE_TIME_INPUT_FORMAT } from "../../../common";
import moment from "moment";
import { useState } from "react";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { restoresMeterSchedules } from "../../../services";
import { useParams } from "react-router-dom";

interface Props {
    isOpen: boolean;
    handleClose: () => void;
}

interface RestoreDataForm {
    since: string;
    until: string;
}

const schema = z.object({
    since: z.string().min(1, "Este campo es obligatorio"),
    until: z.string().min(1, "Este campo es obligatorio"),
});

const RestoreModal = ({ isOpen, handleClose }: Props) => {
    const {
        control,
        handleSubmit,
        reset,
        formState: { errors: formErrors },
    } = useForm<RestoreDataForm>({
        defaultValues: { since: "", until: "" },
        resolver: zodResolver(schema),
    });

    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<boolean>(false);
    const [success, setSuccess] = useState<boolean>(false);

    const { id } = useParams<{ id: string }>();

    const onSubmit = async (data: RestoreDataForm) => {
        if (!id) return;
        setError(false);
        setSuccess(false);
        setLoading(true);
        try {
            await restoresMeterSchedules({
                meterId: id,
                since: moment(data.since)
                    .utcOffset(-5)
                    .format("YYYY-MM-DDTHH:mm:ssZ"),
                until: moment(data.until)
                    .utcOffset(-5)
                    .format("YYYY-MM-DDTHH:mm:ssZ"),
            });
            setSuccess(true);
        } catch (_error) {
            setError(true);
        } finally {
            setLoading(false);
        }
    };

    const onClose = () => {
        reset();
        setError(false);
        setSuccess(false);
        setLoading(false);
        handleClose();
    };

    return (
        <StyledModal
            open={isOpen}
            onClose={onClose}
            title="Recuperación de datos"
        >
            <form onSubmit={handleSubmit(onSubmit)}>
                <Grid container columnSpacing={2} rowSpacing={2}>
                    <Grid item xs={12} md={6}>
                        <Controller
                            control={control}
                            name="since"
                            render={({ field }) => (
                                <LocalizationProvider
                                    dateAdapter={AdapterMoment}
                                >
                                    <InputLabel>Desde</InputLabel>
                                    <FormControl fullWidth>
                                        <DateTimePicker
                                            {...field}
                                            format={DATE_TIME_INPUT_FORMAT}
                                            value={
                                                field.value
                                                    ? moment(field.value)
                                                    : null
                                            }
                                            maxDate={moment()}
                                            onChange={(value) =>
                                                field.onChange(
                                                    value?.toISOString() || "",
                                                )
                                            }
                                            slotProps={{
                                                textField: {
                                                    fullWidth: true,
                                                    error: !!formErrors.since,
                                                    helperText:
                                                        !!formErrors.since &&
                                                        formErrors.since
                                                            .message,
                                                    // sx: datePickerStyles,
                                                },
                                                popper: {
                                                    placement: "left",
                                                },
                                            }}
                                        />
                                    </FormControl>
                                </LocalizationProvider>
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Controller
                            control={control}
                            name="until"
                            render={({ field }) => (
                                <LocalizationProvider
                                    dateAdapter={AdapterMoment}
                                >
                                    <InputLabel>Hasta</InputLabel>
                                    <FormControl fullWidth>
                                        <DateTimePicker
                                            {...field}
                                            format={DATE_TIME_INPUT_FORMAT}
                                            value={
                                                field.value
                                                    ? moment(field.value)
                                                    : null
                                            }
                                            maxDate={moment()}
                                            onChange={(value) =>
                                                field.onChange(
                                                    value?.toISOString() || "",
                                                )
                                            }
                                            slotProps={{
                                                textField: {
                                                    fullWidth: true,
                                                    error: !!formErrors.until,
                                                    helperText:
                                                        !!formErrors.until &&
                                                        formErrors.until
                                                            .message,
                                                    // sx: datePickerStyles,
                                                },
                                                popper: {
                                                    placement: "right",
                                                },
                                            }}
                                        />
                                    </FormControl>
                                </LocalizationProvider>
                            )}
                        />
                    </Grid>
                    {error && (
                        <Grid item xs={12}>
                            <CustomAlert
                                text="Error al enviar comando de recuperación"
                                severity="error"
                                onClose={() => {}}
                            />
                        </Grid>
                    )}
                    {success && (
                        <Grid item xs={12}>
                            <CustomAlert
                                text="Comando de recuperación enviado"
                                severity="success"
                                onClose={() => {}}
                            />
                        </Grid>
                    )}
                    <Grid item xs={12}>
                        <LoadingButton
                            fullWidth
                            variant="contained"
                            color="secondary"
                            type="submit"
                            loading={loading}
                        >
                            Recuperar
                        </LoadingButton>
                    </Grid>
                </Grid>
            </form>
        </StyledModal>
    );
};

export default RestoreModal;

import { api } from "@enerbit/base";
import { GetUsagesParams, Usages } from "../models";

export const getMeterUsagesService = async ({
    serial,
    since,
    until,
}: GetUsagesParams) => {
    try {
        const params = {
            since,
            until,
            period_number: 15,
            period_str: "minute",
        };
        const res = await api.get<Usages[]>(`/odin/meters/usages/${serial}`, {
            params,
        });
        return res.data;
    } catch (_error) {
        throw new Error("ErrGettingUsages");
    }
};

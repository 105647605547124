import {
    Box,
    Button,
    CircularProgress,
    CustomAlert,
    Divider,
    Typography,
    enerbitColors,
} from "@enerbit/base";
import SectionContainer from "../../../components/container/section-container";
import { ReactFlow } from "@xyflow/react";
import "@xyflow/react/dist/style.css";
import { useMemo, useRef, useState, useEffect } from "react";
import MeterNode from "./meter-node";
import SmartbitNode from "./smartbit-node";
import { useDeviceStore } from "../../../store";
import MetersModal from "./meters-modal";

interface Props {
    id: string;
}

const MetersRelationships = ({ id }: Props) => {
    const containerRef = useRef<HTMLDivElement>(null);
    const [sbNodeWith, setSbNodeWidth] = useState(380);
    const [meterNodeWidth, setMeterNodeWidth] = useState(330);
    const [openModal, setOpenModal] = useState<boolean>(false);

    const { loading, error, nodes, edges, getMeterRelationships } =
        useDeviceStore((state) => state);

    useEffect(() => {
        const updateWidth = () => {
            if (containerRef.current) {
                setSbNodeWidth(containerRef.current.offsetWidth - 15);
                setMeterNodeWidth(containerRef.current.offsetWidth - 75);
            }
        };
        updateWidth();
        window.addEventListener("resize", updateWidth);
        return () => window.removeEventListener("resize", updateWidth);
    }, []);

    useEffect(() => {
        getMeterRelationships({ meterId: id, page: 0 });
    }, [id]);

    const nodeTypes = useMemo(
        () => ({
            // biome-ignore lint/suspicious/noExplicitAny: <explanation>
            smartBit: (props: any) => (
                <SmartbitNode {...props} width={sbNodeWith} />
            ),
            // biome-ignore lint/suspicious/noExplicitAny: <explanation>
            meter: (props: any) => (
                <MeterNode {...props} width={meterNodeWidth} />
            ),
        }),
        [sbNodeWith, meterNodeWidth],
    );

    return (
        <SectionContainer>
            <Typography
                sx={{
                    fontWeight: 700,
                    color: enerbitColors.primary.main,
                    fontSize: "18px",
                }}
            >
                Relaciones
            </Typography>
            <Divider sx={{ my: 1, mx: "-12px" }} />
            {loading && (
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <CircularProgress />
                </Box>
            )}
            {!loading && error && (
                <CustomAlert
                    severity="error"
                    text="Error cargando medidores."
                    onClose={() => {}}
                />
            )}
            {!loading && !error && (
                <>
                    <div
                        ref={containerRef}
                        style={{
                            height: edges.length === 1 ? "170px" : "250px",
                        }}
                    >
                        <ReactFlow
                            nodes={nodes.slice(0, 3)}
                            edges={edges.slice(0, 2)}
                            nodeTypes={nodeTypes}
                            zoomOnPinch={false}
                            zoomOnScroll={false}
                            zoomOnDoubleClick={false}
                            draggable={false}
                            panOnDrag={false}
                            preventScrolling={true}
                            nodesDraggable={false}
                        />
                    </div>
                    {edges.length > 2 && (
                        <Box
                            sx={{
                                mt: 2,
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                        >
                            <Button
                                variant="text"
                                color="primary"
                                onClick={() => setOpenModal(true)}
                            >
                                Ver más
                            </Button>
                        </Box>
                    )}
                </>
            )}
            <MetersModal
                open={openModal}
                handleClose={() => setOpenModal(false)}
                id={id}
            />
        </SectionContainer>
    );
};

export default MetersRelationships;

import { api } from "@enerbit/base";
import { ICategoryItem, IMaterialsItemERP } from "../interfaces/ICategory";

export const listCategoriesFn = async () => {
    const { data } = await api.get("/erp-inventory/categories");
    const response = data.items;
    const categories = response
        .map((category: ICategoryItem) => category.name)
        .sort((a: string, b: string) => a.localeCompare(b));

    return categories;
};

export const formatMaterial = async (category: string) => {
    const { data } = await api.get(`/erp-inventory/items?category=${category}`);

    const materials = data.items.map((material: IMaterialsItemERP) => {
        return {
            ["Texto breve material"]: material.name,
            ["Valor unitario con IVA"]: material.valuation_rate,
            ["Unidad de medida"]: material.stock_uom,
            ["Código (SAP)"]: material.custom_sap,
            ["item_code"]: material.item_code,
        };
    });

    return materials;
};

import { api } from "@enerbit/base";
import {
    IMeterLastReport,
    Meter,
    MeterModelsResponse,
    MetersResponse,
    SearchMetersParams,
} from "../models/meters";

export const searchMetersService = async ({
    page,
    filters,
}: SearchMetersParams) => {
    try {
        const params: Record<string, number | string | boolean> = {
            page,
            size: 20,
        };

        if (filters) {
            Object.keys(filters).forEach((key) => {
                if (filters[key]) params[key] = filters[key];
            });
        }

        const res = await api.get<MetersResponse>("/odin/meters/meters", {
            params,
        });

        return res.data;
    } catch (_error) {
        throw new Error("ErrSearchingMeters");
    }
};

export const getMeterModelsService = async () => {
    try {
        const params: Record<string, number | string | boolean> = {
            page: 0,
            size: 200,
        };
        const res = await api.get<MeterModelsResponse>("/odin/meters/models", {
            params,
        });

        return res.data;
    } catch (_error) {
        throw new Error("ErrGettingMeterModels");
    }
};

export const getMeterService = async (id: string) => {
    try {
        const res = await api.get<Meter>(`/odin/meters/meters/${id}`);

        return res.data;
    } catch (_error) {
        throw new Error("ErrGettingMeter");
    }
};

export const restoresMeterSchedules = async ({
    meterId,
    since,
    until,
}: {
    meterId: string;
    since: string;
    until: string;
}) => {
    try {
        const params: Record<string, unknown> = { since, until };
        const {} = await api.post(
            `/electricity-supply-service/device/meters/${meterId}/restore-schedules/`,
            undefined,
            { params },
        );
    } catch (_error) {
        throw new Error("ErrRestoringSchedules");
    }
};

export const getLastReports = async (meterSerial: string) => {
    try {
        const { data } = await api.get<IMeterLastReport>(
            `/verification/meters/last-report?meter_serial=${meterSerial}`,
        );
        return data;
    } catch (_error) {
        throw new Error("ErrGettingLastReport");
    }
};

import { api } from "@enerbit/base";
import {
    EventTypes,
    IEvents,
    IPaginatedResponse,
    SearchEventsParams,
} from "../models";

export const getEventsService = async ({
    page,
    filters,
}: SearchEventsParams) => {
    try {
        const params: Record<string, unknown> = { page, size: 10 };

        Object.keys(filters).forEach((key) => {
            if (Array.isArray(filters[key])) {
                params[key] = filters[key].join(",");
            } else {
                params[key] = filters[key];
            }
        });

        const res = await api.get<IPaginatedResponse<IEvents>>(
            "/odin/meters/events",
            { params },
        );

        return res.data;
    } catch (_error) {
        throw new Error("ErrGettingEvents");
    }
};

export const getEventTypesService = async () => {
    try {
        const { data } = await api.get<EventTypes[]>(
            "/odin/meters/event-types",
        );
        return data;
    } catch (_error) {
        throw new Error("ErrGettingEventTypes");
    }
};

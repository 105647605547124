import {
    Box,
    CircularProgress,
    CustomAlert,
    Pagination,
    Stack,
    styled,
} from "@enerbit/base";
import React, { useEffect } from "react";
import NotificationItem from "./NotificationItem";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentPage } from "../../store/slices/notificationsSlice";
import { AppDispatch, RootState } from "../../store/store";
import { getNotifications } from "../../store/thunks/notifications";

interface Props {
    onlyNoRead: boolean;
}

const NotificationsList = ({ onlyNoRead }: Props) => {
    const dispatch = useDispatch<AppDispatch>();
    const { currentPage, totalPages, notifications, error, loading } =
        useSelector((state: RootState) => state.notifications);

    const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
        event.preventDefault();
        dispatch(setCurrentPage(value - 1));
        dispatch(getNotifications(onlyNoRead));
    };

    useEffect(() => {
        dispatch(getNotifications(onlyNoRead));
    }, []);

    return (
        <>
            {notifications.length > 0 && !loading && !error && (
                <Box>
                    {notifications.map((notification) => (
                        <Box
                            sx={{
                                borderBottom: 1,
                                borderColor: "#E4E7EC",
                                marginLeft: "-24px",
                                marginRight: "-24px",
                            }}
                        >
                            <NotificationItem notification={notification} />
                        </Box>
                    ))}
                    <PaginationContainer>
                        <Stack spacing={2}>
                            <Pagination
                                page={currentPage + 1}
                                count={totalPages}
                                shape="rounded"
                                onChange={handleChange}
                            />
                        </Stack>
                    </PaginationContainer>
                </Box>
            )}
            {loading && (
                <Box
                    my={2}
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                    <CircularProgress />
                </Box>
            )}
            {error && (
                <CustomAlert
                    text="Error cargando las notificaciones."
                    severity="error"
                    onClose={() => {}}
                />
            )}
            {notifications.length === 0 && !error && !loading && (
                <CustomAlert
                    text="No hay notificaciones para ver."
                    severity="info"
                    onClose={() => {}}
                />
            )}
        </>
    );
};

export default NotificationsList;

const PaginationContainer = styled("div")`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 15px;
`;

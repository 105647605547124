import React, { useState } from "react";
import {
    AdapterMoment,
    Box,
    Button,
    DatePicker,
    FilterListIcon,
    FormControl,
    FormHelperText,
    KeyboardArrowDownIcon,
    LocalizationProvider,
    MenuItem,
    Typography,
    enerbitColors,
} from "@enerbit/base";
import StyledMenu from "../StyledMenu/StyledMenu";
import CustomSelect from "../CustomSelect/CustomSelect";
import moment from "moment";
import { DATE_TIME_FORMAT, LEVELS } from "../../common/notifications";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store/store";
import { setSearchParams } from "../../store/slices/notificationsSlice";
import { getNotifications } from "../../store/thunks/notifications";
import { SearchNotificationsParams } from "../../models/nofitications";

interface Props {
    onlyNoReaded: boolean;
}

const Filter = ({ onlyNoReaded }: Props) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [errorMessage, setErrorMessage] = useState<string | undefined>(
        undefined,
    );
    const open = Boolean(anchorEl);

    const dispatch = useDispatch<AppDispatch>();
    const { searchParams } = useSelector(
        (state: RootState) => state.notifications,
    );

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    const handleSearch = () => {
        setErrorMessage(undefined);
        if (searchParams.since && searchParams.until) {
            if (
                moment(searchParams.since).isAfter(moment(searchParams.until))
            ) {
                setErrorMessage(
                    "La fecha inicial no puede ser mayor a la final.",
                );
                return;
            }
        }
        dispatch(getNotifications(onlyNoReaded));
        setAnchorEl(null);
    };

    const handleDateRange = (
        value: any,
        name: keyof SearchNotificationsParams,
    ) => {
        if (value) {
            dispatch(
                setSearchParams({
                    key: name,
                    value:
                        name === "since"
                            ? value.format(DATE_TIME_FORMAT)
                            : value.endOf("day").format(DATE_TIME_FORMAT),
                }),
            );
        } else {
            dispatch(setSearchParams({ key: name, value: undefined }));
        }
    };

    return (
        <>
            <Button
                sx={{
                    minHeight: "unset",
                    borderRadius: "12px",
                    border: "1px solid #A3A9B6",
                    height: "36px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    gap: 2,
                }}
                onClick={handleClick}
            >
                <FilterListIcon sx={{ color: enerbitColors.neutral.main }} />
                <Typography
                    sx={{ fontSize: "12px", color: enerbitColors.neutral[900] }}
                >
                    Filtro
                </Typography>
                <KeyboardArrowDownIcon
                    sx={{ color: enerbitColors.neutral[300] }}
                />
            </Button>
            <StyledMenu
                anchorEl={anchorEl}
                open={open}
                onClose={handleCloseMenu}
                sx={{ display: "flex", flexDirection: "column" }}
            >
                <Box>
                    <Typography
                        sx={{
                            fontWeight: 700,
                            fontSize: "16px",
                            color: enerbitColors.neutral[700],
                        }}
                    >
                        Filtrar
                    </Typography>
                    <Box mt={1}>
                        <Typography sx={{ color: enerbitColors.neutral[700] }}>
                            Tipo de notificación
                        </Typography>
                        <CustomSelect
                            fullWidth
                            value={searchParams.level}
                            onChange={(e) => {
                                dispatch(
                                    setSearchParams({
                                        key: "level",
                                        value: e.target.value,
                                    }),
                                );
                            }}
                        >
                            {LEVELS.map((item) => (
                                <MenuItem key={item.level} value={item.level}>
                                    {item.name}
                                </MenuItem>
                            ))}
                        </CustomSelect>
                    </Box>
                    <Box mt={1}>
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                            <FormControl fullWidth>
                                <Typography
                                    sx={{ color: enerbitColors.neutral[700] }}
                                >
                                    Desde
                                </Typography>
                                <DatePicker
                                    format="YYYY-MM-DD"
                                    value={moment(searchParams.since ?? "")}
                                    maxDate={moment()}
                                    onChange={(value: any) => {
                                        handleDateRange(value, "since");
                                    }}
                                    slotProps={{
                                        textField: {
                                            fullWidth: true,
                                            error: false,
                                            sx: {
                                                "& fieldset": {
                                                    borderRadius: "12px",
                                                },
                                                "& .MuiInputBase-root": {
                                                    height: "41px",
                                                },
                                                ".MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                                                    {
                                                        borderRadius: "12px",
                                                    },
                                            },
                                        },
                                    }}
                                />
                            </FormControl>
                            <FormControl sx={{ mt: 1 }} fullWidth>
                                <Typography
                                    sx={{ color: enerbitColors.neutral[700] }}
                                >
                                    Hasta
                                </Typography>
                                <DatePicker
                                    format="YYYY-MM-DD"
                                    value={moment(searchParams.until ?? "")}
                                    maxDate={moment()}
                                    onChange={(value: any) => {
                                        handleDateRange(value, "until");
                                    }}
                                    slotProps={{
                                        textField: {
                                            fullWidth: true,
                                            error: false,
                                            sx: {
                                                "& fieldset": {
                                                    borderRadius: "12px",
                                                },
                                                "& .MuiInputBase-root": {
                                                    height: "41px",
                                                },
                                                ".MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                                                    {
                                                        borderRadius: "12px",
                                                    },
                                            },
                                        },
                                    }}
                                />
                            </FormControl>
                        </LocalizationProvider>
                    </Box>
                    {errorMessage && (
                        <FormHelperText sx={{ mt: 2 }} error>
                            {errorMessage}
                        </FormHelperText>
                    )}
                    <Button
                        fullWidth
                        onClick={handleSearch}
                        color="primary"
                        variant="contained"
                        sx={{ height: "41px", mt: 2 }}
                    >
                        Filtrar
                    </Button>
                </Box>
            </StyledMenu>
        </>
    );
};

export default Filter;

import {} from "../../../services";
import {
    Box,
    CustomAlert,
    Pagination,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Typography,
} from "@enerbit/base";
import { useEventsStore } from "../../../store";
import { useEffect } from "react";
import moment from "moment";
import { DATE_TIME_FORMAT, EVENT_TYPES_TO_NAMES } from "../../../common";
import PaginationContainer from "../../../components/container/pagination-container";
import { StyledTableHeader } from "../../../components/Table/StyledTableHeader";
import TableSkeleton from "../../../components/Table/TableSkeleton";
import { getEventCategory } from "../../../utils/events";
import StatusTag from "../../../components/container/status-tag";

interface Props {
    id: string;
}

const tableHeaders: string[] = ["Evento", "Tipo", "Fecha"];

const MeterEvents = ({ id }: Props) => {
    const { getMeterEvents, loading, error, events, currentPage, totalPages } =
        useEventsStore((state) => state);

    useEffect(() => {
        getMeterEvents({ id, page: 0 });
    }, [id]);

    const handlePagination = (
        event: React.ChangeEvent<unknown>,
        value: number,
    ) => {
        event.preventDefault();
        getMeterEvents({ page: value - 1, id });
    };

    const renderStatus = (event: string) => {
        const { bgcolor, fontColor, label } = getEventCategory(event);

        return (
            <StatusTag bgcolor={bgcolor} fontcolor={fontColor}>
                {label}
            </StatusTag>
        );
    };

    return (
        <>
            {loading && (
                <TableContainer sx={{ mt: 2 }}>
                    <TableSkeleton columns={4} rows={3} />
                </TableContainer>
            )}
            {!loading && !error && events && events.length > 0 && (
                <>
                    <TableContainer sx={{ mt: 2 }}>
                        <Table>
                            <StyledTableHeader>
                                {tableHeaders.map((header, index) => (
                                    <TableCell key={`${index + 1}-${header}`}>
                                        {header}
                                    </TableCell>
                                ))}
                            </StyledTableHeader>
                            <TableBody>
                                {events.map((ev) => (
                                    <TableRow key={ev.id}>
                                        <TableCell>
                                            <Box
                                                sx={{
                                                    px: "12px",
                                                    py: "4px",
                                                    backgroundColor: "#F6F6F8",
                                                    borderRadius: "8px",
                                                    display: "inline-block",
                                                }}
                                            >
                                                <Typography
                                                    sx={{
                                                        fontWeight: 700,
                                                        color: "#49494C",
                                                    }}
                                                >
                                                    {EVENT_TYPES_TO_NAMES[
                                                        ev.event_types.event
                                                    ] ?? "Desconocido"}
                                                </Typography>
                                            </Box>
                                        </TableCell>
                                        <TableCell>
                                            {renderStatus(ev.event_types.event)}
                                        </TableCell>
                                        <TableCell>
                                            {moment(ev.time_sender).format(
                                                DATE_TIME_FORMAT,
                                            )}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <PaginationContainer sx={{ mt: 2 }}>
                        <Stack spacing={2}>
                            <Pagination
                                page={currentPage + 1}
                                count={totalPages}
                                shape="rounded"
                                onChange={handlePagination}
                            />
                        </Stack>
                    </PaginationContainer>
                </>
            )}
            {!loading && error && (
                <CustomAlert
                    sx={{ mt: 2 }}
                    severity="error"
                    text={"Error listando órdenes de servicio."}
                    onClose={() => {}}
                />
            )}
            {!loading && !error && events.length === 0 && (
                <CustomAlert
                    sx={{ mt: 2 }}
                    severity="info"
                    text={
                        "No se encontraron órdenes de servicio para este medidor."
                    }
                    onClose={() => {}}
                />
            )}
        </>
    );
};

export default MeterEvents;

import { NotiProvider, ThemeConfig, initEnviroment } from "@enerbit/base";
import { hot } from "react-hot-loader/root";
import { Provider } from "react-redux";
import { store } from "./store/store";
import MainLayout from "./layouts/MainLayout";
import Router from "./router/Router";

initEnviroment({
    baseUrl: process.env.REACT_APP_BASE_URL,
});

export default hot(function Root() {
    return (
        <Provider store={store}>
            <ThemeConfig>
                <MainLayout>
                    <NotiProvider>
                        <Router />
                    </NotiProvider>
                </MainLayout>
            </ThemeConfig>
        </Provider>
    );
});

import { ObjectKeyValue } from "@enerbit/base/common/models/customPropsComponents";

export interface MetersState {
    loading: boolean;
    error: boolean;
    meters: Meter[];
    meter: MeterGeneralInfo | null;
    metetModels: MeterModel[];
    currentPage: number;
    totalMeters: number;
    totalPages: number;
    searchMeters: (props: SearchMetersParams) => Promise<void>;
    getMeterModels: () => Promise<void>;
    getMeter: (id: string) => Promise<void>;
}

export interface MetersResponse {
    items: Meter[];
    page: number;
    size: number;
    total: number;
    pages: number;
    next_page: number | null;
    previous_page: number | null;
}

export interface Meter {
    serial: string;
    state_id: string;
    model_id: string;
    group_id: string;
    id: string;
    state: { id: string; name: MeterState };
    created_at: string;
    instalation_date: string | null;
    retirement_date: null;
    validated: boolean;
    meter_model: MeterModel;
}

export interface MeterModel {
    name: string;
    code: string;
    id: string;
    brand: { id: string; name: string };
    meter_connection_type: Type;
    power_supply_type: Type;
    communication_protocol_type: CommunicationProtocolType;
}

export interface CommunicationProtocolType {
    code: string;
    name: string;
    description: string;
    communication_type: string;
    id: string;
    created_at: string;
    updated_at: string | null;
}

export interface Type {
    name: string;
    description: string;
    id: string;
}

export interface SearchMetersParams {
    page: number;
    filters?: ObjectKeyValue;
}

export interface MeterModelsResponse {
    items: MeterModel[];
    page: number;
    size: number;
    total: number;
    pages: number;
    next_page: number | null;
    previous_page: number | null;
}

export interface MeterModel {
    name: string;
    code: string;
    id: string;
    brand: { id: string; name: string };
    meter_connection_type: Type;
    power_supply_type: Type;
    communication_protocol_type: CommunicationProtocolType;
}

export enum MeterState {
    Active = "Activo",
    Activated = "Activado",
    PerActive = "Listo para activar",
    Maintenance = "Mantenimiento",
    Retired = "Retirado",
    Lab = "Laboratorio",
    Store = "Bodega",
}

export interface MeterGeneralInfo {
    serial: string;
    model: string;
    brand: string;
    communicationProtocol: string;
}

export interface Multipliers {
    created_at: string;
    current_multiplier: number;
    end: string | null;
    id: string;
    meter_serial: string;
    start: string;
    voltage_multiplier: number;
}

export interface GetMultipliersParams {
    serial: string;
    page: number;
    size?: number
}

export interface IMeterLastReport {
    active_energy_imported: number;
    current_l1: number;
    current_l2: number;
    current_l3: number;
    power_factor_l1: number;
    power_factor_l2: number;
    power_factor_l3: number;
    reactive_energy_imported: number;
    time_local: string;
    time_local_utc: string;
    voltage_l1n: number;
    voltage_l2n: number;
    voltage_l3n: number;
    active_energy_exported: string;
    reactive_energy_exported: string;
}

export interface NotificationsState {
    notifications: Notification[];
    currentPage: number;
    topics: Topic[];
    userTopics: string[];
    nextPage: number;
    totalPages: number;
    loading: boolean;
    loadingTopics: boolean;
    loadingUserTopics: boolean;
    error: boolean;
    errorTopics: boolean;
    errorUserTopics: boolean;
}

export interface NotificationsResponse {
    items: Notification[];
    page: number;
    size: number;
    pages: number;
    total: number;
}

export interface Notification {
    id: string;
    subject: string;
    body: string;
    sent_to: null | string;
    sent_to_token: null | string;
    metadata: any;
    level: string;
    priority: Priority;
    category: string;
    is_read: boolean;
    topic: Topic | null;
    expire_utc: string;
    created_at: string;
}

export enum Priority {
    Low = "LOW",
    Medium = "MEDIUM",
    High = "HIGH",
    Urgent = "URGENT",
}

export interface Topic {
    name: string;
    description: string;
    id: string;
    created_at: string;
    code: TopicCode;
}

export enum TopicCode {
    ORDER_CANCELATION = "operation.so.cancelation.emit",
    PARTIAL_ORDER_DONE = "operation.so.partial_done.emit",
    NEW_ORDER = "operation.orders.created",
}

export interface Usages {
    active_energy_exported: number;
    active_energy_imported: number;
    end: string;
    is_estimated: boolean;
    reactive_energy_exported: number;
    reactive_energy_imported: number;
    start: string;
    status?: UsagesStatus;
}

export interface GetUsagesParams {
    serial: string;
    since: string;
    until: string;
}

export enum UsagesStatus {
    EXISTS = "exists",
    MISSING = "missing",
    FUTURE = "future",
    ESTIMATED = "estimated",
}

export enum EUnits {
    Wh = "Wh",
    kWh = "kWh",
}

import { hot } from "react-hot-loader/root";
import { Provider } from "react-redux";
import { store } from "./store/store";
import Notifications from "./pages/Notifications";
import { initEnviroment, ThemeConfig } from "@enerbit/base";
import { useEffect } from "react";

initEnviroment({
    baseUrl: process.env.REACT_APP_BASE_URL,
    // firebaseVapidKey: process.env.REACT_APP_FIREBASE_VAPIDKEY,
    // firebaseApiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    // firebaseAppId: process.env.REACT_APP_FIREBASE_APP_ID,
    // firebaseAuthDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    // firebaseMessagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    // firebaseProjectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    // firebaseStorageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET_ID,
});

interface Props {
    handleClose: () => void;
}

export default hot(function Root({ handleClose }: Props) {
    return (
        <Provider store={store}>
            <ThemeConfig>
                <Notifications handleClose={handleClose} />
            </ThemeConfig>
        </Provider>
    );
});

import {
    BoltOutlinedIcon,
    Box,
    Button,
    CircularProgress,
    CustomAlert,
    enerbitColors,
    IconButton,
    Typography,
} from "@enerbit/base";
import MeasurementsOverviewModal from "./measurements-overview-modal";
import { useEffect, useState } from "react";
import HistoryOutlinedIcon from "@mui/icons-material/HistoryOutlined";
import { getLastReports, getMeterService } from "../../../services";
import { IMeterLastReport } from "../../../models";
import moment from "moment";

interface Props {
    id: string;
}

const MeasurementOverview = ({ id }: Props) => {
    const [openModal, setOpenModal] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<boolean>(false);
    const [lastReport, setLastReport] = useState<IMeterLastReport | null>(null);

    const fetchData = async () => {
        setError(false);
        setLoading(true);
        try {
            const { serial } = await getMeterService(id);
            const lastReport = await getLastReports(serial);

            setLastReport(lastReport);
        } catch (_error) {
            setError(true);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, [id]);

    return (
        <>
            <Box
                sx={{
                    borderRadius: "8px",
                    background:
                        "linear-gradient(45deg, #501C7C 0%, #66249E 100%)",
                    padding: "12px",
                }}
            >
                {error && (
                    <CustomAlert
                        text="Error cargando último reporte."
                        severity="error"
                        onClose={() => {}}
                    />
                )}
                {loading && (
                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                        }}
                    >
                        <CircularProgress sx={{ color: "white" }} />
                    </Box>
                )}
                {!error && !loading && lastReport && (
                    <>
                        <Box
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                            }}
                        >
                            <Box
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: 2,
                                }}
                            >
                                <Box
                                    sx={{
                                        height: "48px",
                                        width: "48px",
                                        borderRadius: "100%",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        backgroundColor:
                                            enerbitColors.primary[100],
                                    }}
                                >
                                    <BoltOutlinedIcon />
                                </Box>
                                <Box
                                    sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            fontSize: "16px",
                                            fontWeight: 500,
                                            color: "white",
                                        }}
                                    >
                                        Energía activa importada
                                    </Typography>
                                    <Typography
                                        sx={{
                                            fontSize: "24px",
                                            fontWeight: 700,
                                            color: "white",
                                        }}
                                    >
                                        {lastReport.active_energy_imported} Wh
                                    </Typography>
                                    <Typography
                                        sx={{
                                            fontSize: "12px",
                                            color: "white",
                                        }}
                                    >
                                        Dato tomado el{" "}
                                        {moment(lastReport.time_local).format(
                                            "YYYY-MM-DD HH:mm",
                                        )}
                                    </Typography>
                                </Box>
                            </Box>
                            <IconButton
                                onClick={fetchData}
                                sx={{
                                    backgroundColor: "#FF7705",
                                    borderRadius: "12px",
                                    height: "32px",
                                    width: "32px",
                                    ":hover": {
                                        backgroundColor: "#FF7705",
                                    },
                                }}
                            >
                                <HistoryOutlinedIcon sx={{ color: "white" }} />
                            </IconButton>
                        </Box>
                        <Button
                            variant="outlined"
                            fullWidth
                            sx={{
                                borderColor: "white !important",
                                color: "white",
                                mt: 2,
                            }}
                            onClick={() => setOpenModal(true)}
                            // endIcon={<ArrowForwardOutlinedIcon />}
                        >
                            Ver otros datos
                        </Button>
                    </>
                )}
            </Box>
            {lastReport && (
                <MeasurementsOverviewModal
                    isOpen={openModal}
                    handleClose={() => setOpenModal(false)}
                    lastReport={lastReport}
                />
            )}
        </>
    );
};

export default MeasurementOverview;

import {
    Box,
    CircularProgress,
    CustomAlert,
    enerbitColors,
    FormControlLabel,
    FormGroup,
    Grid,
    Modal,
    Switch,
    Typography,
    useSnackbar,
} from "@enerbit/base";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    appendUserTopic,
    removeUserTopic,
} from "../../store/slices/notificationsSlice";
import { AppDispatch, RootState } from "../../store/store";
import { searchTopics, getUserTopics } from "../../store/thunks/notifications";
import {
    suscribeToTopic,
    unsuscribeToTopic,
} from "../../services/notifications";
import Backbutton from "../BackButton/Backbutton";

interface Props {
    open: boolean;
    handleClose: () => void;
}

const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    p: 3,
    borderRadius: "10px",
    width: "500px",
};

const SettingsModal = ({ open, handleClose }: Props) => {
    const dispatch = useDispatch<AppDispatch>();

    const {
        userTopics,
        topics,
        loadingUserTopics,
        loadingTopics,
        errorTopics,
        errorUserTopics,
    } = useSelector((state: RootState) => state.notifications);
    const { enqueueSnackbar } = useSnackbar();

    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<boolean>(false);
    const [success, setSuccess] = useState<boolean>(false);

    useEffect(() => {
        dispatch(searchTopics());
        dispatch(getUserTopics());
    }, []);

    const handleCheck = async (
        event: React.ChangeEvent<HTMLInputElement>,
        topicId: string,
    ) => {
        const { checked } = event.target;

        setSuccess(false);
        setError(false);

        if (checked) {
            dispatch(appendUserTopic(topicId));
            suscribeToTopic(topicId)
                .then(() => {
                    setSuccess(true);
                })
                .catch(() => {
                    dispatch(removeUserTopic(topicId));
                    setError(true);
                });
        } else {
            dispatch(removeUserTopic(topicId));
            unsuscribeToTopic(topicId)
                .then(() => {
                    setSuccess(true);
                })
                .catch(() => {
                    dispatch(appendUserTopic(topicId));
                    setError(true);
                });
        }
    };
    return (
        <Modal open={open} onClose={handleClose}>
            <Box sx={style}>
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "end",
                    }}
                >
                    <Backbutton onClick={handleClose} />
                </Box>
                <Typography
                    sx={{ color: enerbitColors.primary.main, fontSize: "24px" }}
                >
                    Configuración de notificaciones
                </Typography>
                {!loadingTopics &&
                    !loadingUserTopics &&
                    !errorTopics &&
                    !errorUserTopics && (
                        <Grid container columnSpacing={3} sx={{ mt: 2 }}>
                            <Grid item md={12}>
                                <Box>
                                    <Typography
                                        sx={{
                                            fontSize: "16px",
                                            color: enerbitColors.neutral[700],
                                            fontWeight: 700,
                                        }}
                                    >
                                        Notificaciones push
                                    </Typography>
                                    <Typography
                                        sx={{
                                            fontSize: "14px",
                                            color: enerbitColors.neutral.main,
                                        }}
                                    >
                                        Recibe actualizaciones sobre témas de tu
                                        interés.
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item md={12}>
                                <FormGroup>
                                    {topics.map((t) => (
                                        <FormControlLabel
                                            key={t.id}
                                            control={
                                                <Switch
                                                    color="secondary"
                                                    disabled={loading}
                                                    checked={userTopics.includes(
                                                        t.id,
                                                    )}
                                                    onChange={(e) => {
                                                        handleCheck(e, t.id);
                                                    }}
                                                />
                                            }
                                            label={
                                                <Box
                                                    sx={{
                                                        mt: "12px",
                                                        ml: "14px",
                                                    }}
                                                >
                                                    <Typography
                                                        sx={{
                                                            color: enerbitColors
                                                                .neutral[700],
                                                            fontWeight: 700,
                                                        }}
                                                    >
                                                        {t.name}
                                                    </Typography>
                                                    <Typography
                                                        sx={{
                                                            color: enerbitColors
                                                                .neutral.main,
                                                        }}
                                                    >
                                                        {t.description}
                                                    </Typography>
                                                </Box>
                                            }
                                        />
                                    ))}
                                </FormGroup>
                            </Grid>
                        </Grid>
                    )}
                {(loadingTopics || loadingUserTopics) && (
                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                        }}
                    >
                        <CircularProgress />
                    </Box>
                )}
                {error && (
                    <CustomAlert
                        severity="error"
                        text="Error guardando configuración."
                        onClose={() => setError(false)}
                    />
                )}
                {success && (
                    <CustomAlert
                        severity="success"
                        text="Configuración guardada exitosamente."
                        onClose={() => setSuccess(false)}
                    />
                )}
            </Box>
        </Modal>
    );
};

export default SettingsModal;

import {
    Box,
    CachedIcon,
    CircularProgress,
    CustomAlert,
    Divider,
    enerbitColors,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from "@enerbit/base";
import SectionContainer from "../../../components/container/section-container";
import StatusTag from "../../../components/container/status-tag";
import moment from "moment";
import { CustomProvider, DateRangePicker } from "rsuite";
import { esAR } from "rsuite/esm/locales";

import "rsuite/DateRangePicker/styles/index.css";
import "../../../styles/date-range-picker.css";
import { useUsagesStore } from "../../../store";
import { DATE_TIME_INPUT_FORMAT } from "../../../common";
import { useEffect, useState } from "react";
import UsagesState from "./usages-state";
import { DateRange } from "rsuite/esm/DateRangePicker";

interface Props {
    id: string;
}

// const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
//     height: "36px",
//     [`& .${toggleButtonGroupClasses.grouped}`]: {
//         margin: theme.spacing(0.5),
//         border: 0,
//         borderRadius: theme.shape.borderRadius,
//         [`&.${toggleButtonGroupClasses.disabled}`]: {
//             border: 0,
//         },
//         [`&.${toggleButtonGroupClasses.selected}`]: {
//             backgroundColor: enerbitColors.primary.main,
//             color: "#fff",
//         },
//     },
//     [`& .${toggleButtonGroupClasses.middleButton},& .${toggleButtonGroupClasses.lastButton}`]:
//         {
//             marginLeft: -1,
//             borderLeft: "1px solid transparent",
//         },
// }));

const hours = new Array(24)
    .fill(0)
    .map((_, index) => (index < 10 ? "0" + index : index.toString()));

const MeterUsages = ({ id }: Props) => {
    const { loading, getUsages, error, usages } = useUsagesStore(
        (state) => state,
    );
    const [value, setValue] = useState<DateRange | null>([
        moment().subtract(4, "day").toDate(),
        moment().toDate(),
    ]);

    // const handleAlignment = (
    //     _event: React.MouseEvent<HTMLElement>,
    //     newAlignment: EUnits,
    // ) => {
    //     setCurrentUnits(newAlignment);
    // };

    const onOk = (value: [Date, Date]) => {
        const since = moment(value[0])
            .startOf("day")
            .format(DATE_TIME_INPUT_FORMAT);
        let until: string;
        if (moment().isSame(value[1], "day")) {
            until = moment().format(DATE_TIME_INPUT_FORMAT);
        } else {
            until = moment(value[1])
                .endOf("day")
                .format(DATE_TIME_INPUT_FORMAT);
        }
        getUsages({ id, since, until });
    };

    const getInitialData = () => {
        setValue([moment().subtract(4, "day").toDate(), moment().toDate()]);
        const since = moment()
            .subtract(4, "day")
            .startOf("day")
            .format(DATE_TIME_INPUT_FORMAT);
        const until = moment().format(DATE_TIME_INPUT_FORMAT);
        getUsages({ id, since, until });
    };

    useEffect(() => {
        getInitialData();
    }, []);

    return (
        <SectionContainer>
            <Typography
                sx={{
                    fontWeight: 700,
                    color: enerbitColors.primary.main,
                    fontSize: "18px",
                }}
            >
                Datos
            </Typography>
            <Divider sx={{ my: 1, mx: "-12px" }} />
            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                }}
            >
                <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                    <Typography sx={{ color: enerbitColors.neutral[700] }}>
                        Estado:
                    </Typography>
                    <StatusTag
                        bgcolor={enerbitColors.success[100] || ""}
                        fontcolor={enerbitColors.success.main || ""}
                    >
                        Dato completo
                    </StatusTag>
                    <StatusTag
                        bgcolor={enerbitColors.warning[100] || ""}
                        fontcolor={enerbitColors.warning.main || ""}
                    >
                        Dato estimado
                    </StatusTag>
                    <StatusTag
                        bgcolor={enerbitColors.error[100] || ""}
                        fontcolor={enerbitColors.error.main || ""}
                    >
                        Dato faltante
                    </StatusTag>
                    <StatusTag
                        bgcolor={enerbitColors.neutral[100] || ""}
                        fontcolor={enerbitColors.neutral.main || ""}
                    >
                        Fuera de hora
                    </StatusTag>
                </Box>
            </Box>
            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "right",
                    my: 2,
                    gap: 2,
                }}
            >
                {/* <Box
                    sx={{
                        border: "1px solid #A3A9B6",
                        borderRadius: "8px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                    <StyledToggleButtonGroup
                        size="small"
                        value={currentUnits}
                        exclusive
                        onChange={handleAlignment}
                    >
                        <ToggleButton
                            value={EUnits.kWh}
                            sx={{
                                textTransform: "none",
                                ":hover": {
                                    backgroundColor: "none",
                                },
                            }}
                        >
                            kWh
                        </ToggleButton>
                        <ToggleButton
                            value={EUnits.Wh}
                            sx={{
                                textTransform: "none",
                                ":hover": {
                                    backgroundColor: "transparent",
                                },
                            }}
                        >
                            Wh
                        </ToggleButton>
                    </StyledToggleButtonGroup>
                </Box> */}
                <CustomProvider locale={esAR}>
                    <DateRangePicker
                        showOneCalendar
                        format="yyyy-MM-dd"
                        size="md"
                        placeholder="Selecciona el rango de fechas"
                        placement="top"
                        showHeader={false}
                        ranges={[]}
                        onOk={onOk}
                        value={value}
                        onChange={(date) => {
                            setValue(date);
                        }}
                        // loading={loading}
                        shouldDisableDate={(date) =>
                            moment(date).isAfter(moment())
                        }
                    />
                </CustomProvider>
                <IconButton color="primary" onClick={getInitialData}>
                    <CachedIcon />
                </IconButton>
                {/* <Button
                    variant="contained"
                    color="secondary"
                    onClick={handleDownload}
                >
                    <DownloadOutlinedIcon />
                </Button> */}
            </Box>
            {loading && (
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        mt: 1,
                    }}
                >
                    <CircularProgress />
                </Box>
            )}
            {error && !loading && (
                <CustomAlert
                    severity="error"
                    text={"Error cargando datos."}
                    onClose={() => {}}
                    sx={{ mt: 1 }}
                />
            )}

            {usages && !loading && !error && (
                <TableContainer
                    sx={{ mt: 2, maxWidth: "100%", maxHeight: 310 }}
                >
                    <Table
                        stickyHeader
                        sx={{
                            "& th": {
                                borderCollapse: "collapse",
                                backgroundColor: "#FFF !important",
                                padding: "3px !important",
                                width: "auto",
                                whiteSpace: "nowrap",
                            },
                            "& td": {
                                padding: "3px !important",
                                borderCollapse: "collapse",
                                width: "auto",
                                whiteSpace: "nowrap",
                                borderBottom: "none",
                            },
                        }}
                    >
                        <TableHead>
                            <TableRow>
                                <TableCell>Fecha</TableCell>
                                {hours.map((h) => (
                                    <TableCell
                                        key={h}
                                        align="center"
                                    >{`H${h}`}</TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {Object.keys(usages)
                                .reverse()
                                .map((date) => (
                                    <TableRow key={date}>
                                        <TableCell>{date}</TableCell>
                                        {Array.from(usages[date]).map(
                                            (item) => (
                                                <TableCell
                                                    key={`${date}-${item[0]}`}
                                                >
                                                    <UsagesState
                                                        usages={item[1]}
                                                        date={date}
                                                        hour={item[0]}
                                                    />
                                                </TableCell>
                                            ),
                                        )}
                                    </TableRow>
                                ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}
        </SectionContainer>
    );
};

export default MeterUsages;

import { useEffect, useState } from "react";
import { Usages, UsagesStatus } from "../../../models";
import {
    Box,
    enerbitColors,
    IconButton,
    Popover,
    styled,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from "@enerbit/base";
import moment from "moment";
import { generateRandomId } from "../../../utils";
import RemoveIcon from "@mui/icons-material/Remove";

interface Props {
    usages: Usages[];
    date: string;
    hour: string;
}

const StyledPopover = styled(Popover)(() => ({
    "& .MuiPaper-root": {
        border: `1px solid ${enerbitColors.primary.main}`,
    },
}));

const measures: { label: string; units: string; key: keyof Usages }[] = [
    {
        label: "Ener. Act. Import",
        units: "Wh",
        key: "active_energy_imported",
    },
    {
        label: "Ener. Act. export",
        units: "Wh",
        key: "active_energy_exported",
    },
    {
        label: "Ener. Reac. Export",
        units: "VArh",
        key: "reactive_energy_exported",
    },
    {
        label: "Ener. Reac. Import",
        units: "VArh",
        key: "reactive_energy_imported",
    },
];

const getStatusColor = (data: Usages[]) => {
    const colors: Record<
        UsagesStatus,
        Record<string, { border: string; background: string }>
    > = {
        exists: { "0": { background: "#F6FEF9", border: "#CAEFDE" } },
        estimated: {
            "0": { border: "#FEC84B", background: "#FEEFC7" },
            "25": { border: "#FEC84B", background: "#FEDF89" },
            "75": { border: "#F79009", background: "#FDB022" },
            "100": { border: "#93370D", background: "#DC6803" },
        },
        missing: {
            "0": { border: "#FDA29B", background: "#FEE4E2" },
            "25": { border: "#F97066", background: "#FDA29B" },
            "75": { border: "#D92D20", background: "#F04438" },
            "100": { border: "#912018", background: "#B42318" },
        },
        future: { "0": { border: "#E4E7EC", background: "#F9FAFB" } },
    };

    const missingCount = data.filter(
        (usage) => usage.status === UsagesStatus.MISSING,
    ).length;
    const percentage = (missingCount / data.length) * 100;

    if (missingCount === 0) {
        const isFuture = data.some(
            (usage) => usage.status === UsagesStatus.FUTURE,
        );

        if (isFuture) {
            return colors.future["0"];
        } else {
            return colors.exists["0"];
        }
    }

    return colors.missing[percentage.toString()];
};

const UsagesState = ({ usages, date, hour }: Props) => {
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const [colors, setColors] = useState<{
        border: string;
        background: string;
    }>({ background: "", border: "" });

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        if (usages.some((usage) => usage.status === UsagesStatus.FUTURE))
            return;
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;

    const formatHour = (hour: string) => {
        return moment(hour).format("HH:mm");
    };

    useEffect(() => {
        setColors(getStatusColor(usages));
    }, [usages]);

    return (
        <>
            <IconButton
                onClick={handleClick}
                sx={{
                    backgroundColor: colors.background,
                    border: `1px solid ${colors.border}`,
                    borderRadius: "4px",
                    height: "22px",
                    width: "22px",
                    cursor: "pointer",
                    ":hover": {
                        backgroundColor: colors.background,
                    },
                }}
            >
                {usages.some(
                    (usage) => usage.status === UsagesStatus.FUTURE,
                ) && (
                    <RemoveIcon
                        sx={{
                            fontSize: "16px",
                            color: enerbitColors.neutral.main,
                        }}
                    />
                )}
            </IconButton>
            <StyledPopover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
            >
                <Box sx={{ p: "8px" }}>
                    <Box
                        sx={{
                            backgroundColor: enerbitColors.primary.main,
                            mx: "-8px",
                            mt: "-8px",
                            p: 1,
                            pb: 0,
                        }}
                    >
                        <Typography sx={{ color: "#FFF", fontWeight: 700 }}>
                            Fecha: {date}
                        </Typography>
                        <Typography sx={{ color: "#FFF", fontWeight: 700 }}>
                            Hora: {hour}
                        </Typography>
                    </Box>
                    <TableContainer sx={{ mx: "-8px", width: "unset" }}>
                        <Table
                            stickyHeader
                            sx={{
                                "& th": {
                                    borderBottom:
                                        "1px solid #D9D2E6 !important",
                                    border: "1px solid #D9D2E6",
                                    backgroundColor: "#FFF !important",
                                    padding: "8px !important",
                                    width: "auto",
                                    whiteSpace: "nowrap",
                                },
                                "& td": {
                                    padding: "8px !important",
                                    border: "1px solid #D9D2E6",
                                    width: "auto",
                                    whiteSpace: "nowrap",
                                },
                            }}
                        >
                            <TableHead>
                                <TableRow>
                                    <TableCell align="center">Hora</TableCell>
                                    {usages.map((usg) => (
                                        <TableCell
                                            key={usg.start}
                                            align="center"
                                        >
                                            {formatHour(usg.start)}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {measures.map((item) => (
                                    <TableRow key={item.key}>
                                        <TableCell align="center">
                                            <Typography
                                                sx={{
                                                    fontWeight: 700,
                                                    color: enerbitColors.primary
                                                        .main,
                                                }}
                                            >
                                                {item.label}
                                            </Typography>
                                        </TableCell>
                                        {usages.map((usg) => (
                                            <TableCell
                                                key={generateRandomId()}
                                                align="center"
                                            >
                                                <Typography
                                                    sx={{ fontWeight: 700 }}
                                                >
                                                    {usg[item.key]}
                                                    {item.units}
                                                </Typography>
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
            </StyledPopover>
        </>
    );
};

export default UsagesState;

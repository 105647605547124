import {
  Box,
  IconButton,
  KeyboardArrowDownIcon,
  KeyboardArrowUpIcon,
  Typography,
  detailBillingNames,
} from "@enerbit/base";
import { useState } from "react";

import { SettlementCardsProps } from "../../../../../../../models/props/SettlementCards";
import {
  getInvoiceComponentOutageCompensationByDuration,
  getInvoiceComponentOutageCompensationByFrequency,
} from "../../../../../../../utils/billingUtils";
import CardsEnergyRegulated from "../../insight-cards/CardsEnergyRegulated";
import {
  CardBody,
  CardBodyTitle,
  CardContainer,
  CardFooter,
  CardHeader,
  CardsContainerItems,
} from "../../style-components-cards/CardComponent";
import { SettlementCardRowConcepts } from "./SettlementCardRowConcepts";
import { SettlementCardRowConceptsCompensation } from "./SettlementCardRowConceptsCompensation";
import { SettlementCardRowTotalFooter } from "./SettlementCardRowTotalFooter";
import { SettlementCardRowTotalHeader } from "./SettlementCardRowTotalHeader";
const SettlementEnergyRegulateCard = ({ invoice }: SettlementCardsProps) => {
  const [displayTariff, setDisplayTariff] = useState(true);

  return (
    <Box>
      {true &&
        (invoice ? (
          <>
            <CardContainer>
              <CardHeader
                sx={{
                  borderBottomLeftRadius: displayTariff ? "0rem" : "1rem",
                  borderBottomRightRadius: displayTariff ? "0rem" : "1rem",
                }}
              >
                <SettlementCardRowTotalHeader
                  title="Total energía regulada"
                  backgroundColor={"var(--color-secondary)"}
                  color={"white"}
                  fontWeight={700}
                  value1={Number(invoice.legal_payable_amount)?.toFixed(2)}
                />
                <IconButton
                  size="small"
                  sx={{ color: "white" }}
                  onClick={() => setDisplayTariff(!displayTariff)}
                >
                  {displayTariff ? (
                    <KeyboardArrowDownIcon />
                  ) : (
                    <KeyboardArrowUpIcon />
                  )}
                </IconButton>
              </CardHeader>

              {displayTariff && (
                <>
                  <CardBody>
                    <CardsEnergyRegulated invoice={invoice} />
                    <CardBodyTitle>
                      <Box
                        style={{ width: "20%" }}
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          padding: "0 1rem",
                        }}
                      >
                        <Typography
                          sx={{
                            fontWeight: "700",
                            color: "var(--color-primary)",
                          }}
                        >
                          Concepto
                        </Typography>
                      </Box>
                    </CardBodyTitle>
                    <CardsContainerItems>
                      {invoice?.components.map((component, index) => {
                        const outageCompensationByDuration =
                          getInvoiceComponentOutageCompensationByDuration(
                            component
                          );
                        const outageCompensationByFrequency =
                          getInvoiceComponentOutageCompensationByFrequency(
                            component
                          );

                        if (outageCompensationByDuration) {
                          return (
                            <SettlementCardRowConceptsCompensation
                              key={index}
                              title={
                                detailBillingNames[component?.name!] as string
                              }
                              alignItems="flex-start"
                              value2={outageCompensationByDuration?.amount?.toFixed(
                                2
                              )}
                            />
                          );
                        } else if (outageCompensationByFrequency) {
                          return (
                            <SettlementCardRowConceptsCompensation
                              key={index}
                              title={
                                detailBillingNames[component?.name!] as string
                              }
                              alignItems="flex-start"
                              value2={outageCompensationByFrequency?.amount?.toFixed(
                                2
                              )}
                            />
                          );
                        } else {
                          return (
                            <SettlementCardRowConcepts
                              key={index}
                              title={
                                detailBillingNames[component?.name!] ??
                                ("No hay titulo de concepto disponible" as string)
                              }
                              alignItems="flex-start"
                              value2={component?.amount}
                            />
                          );
                        }
                      })}
                    </CardsContainerItems>
                  </CardBody>

                  <CardFooter>
                    <SettlementCardRowTotalFooter
                      title="Total energía regulada"
                      fontWeight={700}
                      value1={Number(invoice.legal_payable_amount)?.toFixed(2)}
                    />
                  </CardFooter>
                </>
              )}
            </CardContainer>
          </>
        ) : (
          <Box></Box>
        ))}
    </Box>
  );
};
export default SettlementEnergyRegulateCard;

import { api } from "@enerbit/base";

export const updateNotificationStatus = async (id: string, value: boolean) => {
    const res = await api.patch(`/operator-notification/notifications/${id}`, {
        is_read: value,
    });

    return res.data;
};

export const suscribeToTopic = async (topicId: string) => {
    await api.post(`/operator-notification/subscribe?topic_id=${topicId}`);
};

export const unsuscribeToTopic = async (topicId: string) => {
    await api.post(`/operator-notification/unsubscribe?topic_id=${topicId}`);
};

import { api } from "@enerbit/base";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../store";
import {
    NotificationsResponse,
    SearchNotificationsParams,
    Topic,
} from "../../models/nofitications";

export const getNotifications = createAsyncThunk(
    "notifications/getNotifications",
    async (onlyNoReaded: boolean, { getState, rejectWithValue }) => {
        try {
            const state = getState() as RootState;
            const { currentPage, searchParams } = state.notifications;

            const params: any = { ...searchParams };

            if (onlyNoReaded) {
                params["is_read"] = false;
            }

            const res = await api.get<NotificationsResponse>(
                `/operator-notification/notifications?page=${currentPage}&size=25`,
                { params },
            );

            return {
                currentPage: res.data.page,
                totalPages: res.data.pages,
                notifications: res.data.items,
            };
        } catch (error) {
            return rejectWithValue(error);
        }
    },
);

export const searchTopics = createAsyncThunk(
    "notifications-drawer/searchTopics",
    async (_, { rejectWithValue }) => {
        try {
            const res = await api.get<{
                items: Topic[];
                page: number;
                size: number;
            }>("/operator-notification/topics?page=0&size=50");
            return { topics: res.data.items };
        } catch (error) {
            rejectWithValue(error);
        }
    },
);

export const getUserTopics = createAsyncThunk(
    "notification-drawer/getUserTopics",
    async (_, { rejectWithValue }) => {
        try {
            const res = await api.get<{ topics: { topic_id: string }[] }>(
                "/operator-notification/token",
            );
            return res.data.topics.map((item) => item.topic_id);
        } catch (error) {
            return rejectWithValue(error);
        }
    },
);

import { Box, Typography, enerbitColors } from "@enerbit/base";
import { useNavigate } from "react-router-dom";
import Backbutton from "../../../components/Buttons/back-button";

interface Props {
    id: string;
}

const DetailHeader = ({ id }: Props) => {
    const navigate = useNavigate();
    const handleBack = () => {
        navigate(-1);
    };

    return (
        <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
            <Backbutton onClick={handleBack} />
            <Box>
                <Typography sx={{ color: enerbitColors.neutral[700] }}>
                    Medidor
                </Typography>
                <Typography
                    sx={{
                        color: enerbitColors.primary.main,
                        fontSize: "28px",
                        fontWeight: 700,
                    }}
                >
                    {id}
                </Typography>
            </Box>
        </Box>
    );
};

export default DetailHeader;

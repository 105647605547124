import {
    Box,
    FormControl,
    FormHelperText,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography,
    enerbitColors,
} from "@enerbit/base";
import { useEffect } from "react";
import { Controller, get, useFormContext } from "react-hook-form";
import { useAppSelector } from "../../../store";

export const InfoCancellation = () => {
    const { cancellationReasons, cancellationResponsibles } = useAppSelector(
        (state) => state.initialForm,
    );
    const {
        control,
        register,
        watch,
        getValues,
        setValue,
        formState: { errors },
    } = useFormContext();

    const getReasonSpecification = (id: string) => {
        const reason = cancellationReasons.find((item) => item.id === id);

        if (!reason) return "";

        if (reason.code === "ABSDSO") {
            return "El OR-RF no se presentó a la actividad o llegó tarde, antes de cancelar debe tener comunicación con Fronteras, no se puede ejecutar por situaciones atribuibles al OR o RF.";
        }
        return reason.description;
    };

    const renderClientNameField = (reasonId: string) => {
        const reason = cancellationReasons.find((item) => item.id === reasonId);

        if (!reason || reason.code !== "CURS") {
            return;
        }

        return (
            <Grid item xs={12}>
                <InputLabel className="title-input-edition" required>
                    Nombre de quien no permite realizar la actividad
                </InputLabel>
                <TextField
                    fullWidth
                    id="client_name"
                    type="text"
                    sx={{ "& fieldset": { borderRadius: "14px" } }}
                    {...register("form_data.client_name")}
                    error={!!get(errors, "form_data.client_name")}
                    helperText={
                        !!get(errors, "form_data.client_name") &&
                        get(errors, "form_data.client_name").message
                    }
                    variant="outlined"
                />
            </Grid>
        );
    };

    const renderReasonDescription = (reasonId: string) => {
        if (!reasonId) return;
        return (
            <Grid item xs={12}>
                <Box
                    sx={{
                        padding: "12px 16px 12px 16px",
                        border: `1px solid #53358E`,
                        borderRadius: "16px",
                        backgroundColor: "#EEEBF4",
                        color: enerbitColors.primary.main,
                    }}
                >
                    <Typography sx={{ fontWeight: 700 }}>
                        Especificación
                    </Typography>
                    <Typography>{getReasonSpecification(reasonId)}</Typography>
                </Box>
            </Grid>
        );
    };

    useEffect(() => {
        setValue("cancellation_reason_id", "");
        // console.log(getValues("cancellation_responsible"));
    }, [watch("cancellation_responsible")]);

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Typography
                    color="primary"
                    sx={{ fontSize: "28px", fontWeight: 400, mb: "12px" }}
                >
                    Información de la cancelación
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <InputLabel className="title-input-edition" required>
                    Responsable de la visita fallida
                </InputLabel>
                <FormControl fullWidth>
                    <Controller
                        name="cancellation_responsible"
                        control={control}
                        render={({ field }) => (
                            <>
                                <Select
                                    {...field}
                                    sx={{
                                        borderRadius: "14px",
                                        background: "white",
                                    }}
                                    variant="outlined"
                                    fullWidth
                                    error={
                                        !!get(
                                            errors,
                                            "cancellation_responsible",
                                        )
                                    }
                                >
                                    {cancellationResponsibles.map((item) => (
                                        <MenuItem key={item} value={item}>
                                            {item}
                                        </MenuItem>
                                    ))}
                                </Select>
                                <FormHelperText error>
                                    {!!get(
                                        errors,
                                        "cancellation_responsible",
                                    ) &&
                                        get(errors, "cancellation_responsible")
                                            .message}
                                </FormHelperText>
                            </>
                        )}
                        defaultValue=""
                    />
                </FormControl>
            </Grid>
            <Grid item xs={12}>
                <InputLabel className="title-input-edition" required>
                    Motivo de cancelación
                </InputLabel>
                <FormControl fullWidth>
                    <Controller
                        name="cancellation_reason_id"
                        control={control}
                        render={({ field }) => (
                            <>
                                <Select
                                    {...field}
                                    sx={{
                                        borderRadius: "14px",
                                        background: "white",
                                    }}
                                    variant="outlined"
                                    fullWidth
                                    error={
                                        !!get(errors, "cancellation_reason_id")
                                    }
                                >
                                    {!!watch("cancellation_responsible") && []}
                                    {watch("cancellation_responsible") &&
                                        cancellationReasons
                                            .filter(
                                                (item) =>
                                                    item.responsible ===
                                                    getValues(
                                                        "cancellation_responsible",
                                                    ),
                                            )
                                            .map(({ name, id }, index) => (
                                                <MenuItem
                                                    key={index}
                                                    value={id}
                                                >
                                                    {name}
                                                </MenuItem>
                                            ))}
                                </Select>
                                <FormHelperText error>
                                    {!!get(errors, "cancellation_reason_id") &&
                                        get(errors, "cancellation_reason_id")
                                            .message}
                                </FormHelperText>
                            </>
                        )}
                        defaultValue=""
                    />
                </FormControl>
            </Grid>
            {watch("cancellation_reason_id") &&
                renderClientNameField(getValues("cancellation_reason_id"))}
            {watch("cancellation_reason_id") &&
                renderReasonDescription(getValues("cancellation_reason_id"))}
            <Grid item xs={12}>
                <InputLabel className="title-input-edition" required>
                    Observaciones
                </InputLabel>
                <TextField
                    fullWidth
                    id="observations"
                    type="text"
                    sx={{ "& fieldset": { borderRadius: "14px" } }}
                    {...register("observations")}
                    error={!!get(errors, "observations")}
                    helperText={
                        !!get(errors, "observations") &&
                        get(errors, "observations").message
                    }
                    variant="outlined"
                    rows={4}
                    multiline
                />
            </Grid>
        </Grid>
    );
};

import {
    Box,
    Button,
    CustomAlert,
    Pagination,
    Stack,
    SxProps,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
} from "@enerbit/base";
import Filters from "./filters";
import { useMetersState } from "../../../store";
import TableSkeleton from "../../../components/Table/TableSkeleton";
import { StyledTableHeader } from "../../../components/Table/StyledTableHeader";
import PaginationContainer from "../../../components/container/pagination-container";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { DATE_TIME_FORMAT, meterStatusColors } from "../../../common";
import moment from "moment";
import StatusTag from "../../../components/container/status-tag";
import { ObjectKeyValue } from "@enerbit/base/common/models/customPropsComponents";

const TABLE_HEADERS: string[] = [
    "Serial",
    "Modelo",
    "Marca",
    "Fecha de instalación",
    "Estado",
    "Acciones",
];

const detailButtonProps: SxProps = {
    minHeight: "unset !important",
    height: "33px",
    fontSize: "12px",
};

const MetersTable = () => {
    const {
        loading,
        error,
        meters,
        searchMeters,
        currentPage,
        totalMeters,
        totalPages,
        getMeterModels,
    } = useMetersState((state) => state);

    const navigate = useNavigate();

    const [filters, setFilters] = useState<ObjectKeyValue>({
        model_id: "",
        meter_serial: "",
    });

    const handleSearch = async () => {
        await searchMeters({ page: 0, filters });
    };

    const handleFilterChange = (key: string, value: string) => {
        setFilters((prev) => {
            const nD = { ...prev };
            nD[key] = value;
            return nD;
        });
    };

    const handleReset = async () => {
        setFilters({
            model_id: "",
            meter_serial: "",
        });
        await searchMeters({ page: 0 });
    };

    useEffect(() => {
        searchMeters({ page: 0 });
        getMeterModels();
    }, []);

    const handlePagination = (
        event: React.ChangeEvent<unknown>,
        value: number,
    ) => {
        event.preventDefault();
        searchMeters({ page: value - 1, filters });
    };

    return (
        <Box>
            <Filters
                filters={filters}
                handleFilterChange={handleFilterChange}
                handleReset={handleReset}
                handleSearch={handleSearch}
            />

            {loading && (
                <Box sx={{ my: 2 }}>
                    <TableSkeleton columns={6} rows={20} />
                </Box>
            )}
            {!loading && error && (
                <CustomAlert
                    text="Error listando medidores."
                    sx={{ my: 2 }}
                    severity="error"
                    onClose={() => {}}
                />
            )}
            {!loading && !error && totalMeters === 0 && (
                <CustomAlert
                    sx={{ my: 2 }}
                    text="No se encontraron medidores."
                    severity="info"
                    onClose={() => {}}
                />
            )}
            {!loading && !error && totalMeters > 0 && (
                <>
                    <TableContainer sx={{ mt: 2 }}>
                        <Table>
                            <StyledTableHeader>
                                {TABLE_HEADERS.map((header, index) => (
                                    <TableCell key={`${index + 1}-${header}`}>
                                        {header}
                                    </TableCell>
                                ))}
                            </StyledTableHeader>
                            <TableBody>
                                {meters.map((m) => (
                                    <TableRow key={m.id}>
                                        <TableCell>{m.serial}</TableCell>
                                        <TableCell>
                                            {m.meter_model.name}
                                        </TableCell>
                                        <TableCell>
                                            {m.meter_model.brand.name}
                                        </TableCell>
                                        <TableCell>
                                            {m.instalation_date
                                                ? moment(
                                                      m.instalation_date,
                                                  ).format(DATE_TIME_FORMAT)
                                                : "Desconocida"}
                                        </TableCell>
                                        <TableCell>
                                            <StatusTag
                                                bgcolor={
                                                    meterStatusColors[
                                                        m.state.name
                                                    ].bgcolor
                                                }
                                                fontcolor={
                                                    meterStatusColors[
                                                        m.state.name
                                                    ].fontcolor
                                                }
                                            >
                                                {m.state.name}
                                            </StatusTag>
                                        </TableCell>
                                        <TableCell>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                sx={detailButtonProps}
                                                onClick={() =>
                                                    navigate(`/${m.id}`)
                                                }
                                            >
                                                Ver detalle
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <PaginationContainer sx={{ mt: 2 }}>
                        <Stack spacing={2}>
                            <Pagination
                                page={currentPage + 1}
                                count={totalPages}
                                shape="rounded"
                                onChange={handlePagination}
                            />
                        </Stack>
                    </PaginationContainer>
                </>
            )}
        </Box>
    );
};

export default MetersTable;

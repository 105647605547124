import {
    Box,
    Button,
    CachedIcon,
    SxProps,
    Typography,
    enerbitColors,
} from "@enerbit/base";
import Filter from "../components/Filter/Filter";
import { useState } from "react";
import { StyledTab } from "../components/StyledTab/StyledTab";
import { StyledTabs } from "../components/StyledTabs/StyledTabs";
import { TabPanel } from "../components/TabPanel/TabPanel";
import NotificationsList from "../components/NotificationsList/NotificationsList";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../store/store";
import {
    resetSearchParams,
    setCurrentPage,
    setSearchParams,
} from "../store/slices/notificationsSlice";
import { getNotifications } from "../store/thunks/notifications";

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}

const refreshButtonStyles: SxProps = {
    minHeight: "unset !important",
    width: "20px",
    height: "20px",
    borderRadius: "100% !important",
    minWidth: "unset !important",
    padding: "16px !important",
};

const Notifications = () => {
    const [value, setValue] = useState<number>(0);
    const { loading } = useSelector((state: RootState) => state.notifications);

    const dispatch = useDispatch<AppDispatch>();

    const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
        dispatch(setCurrentPage(0));
        setValue(newValue);
    };

    const handleRefresh = () => {
        dispatch(setCurrentPage(0));
        dispatch(resetSearchParams());
        dispatch(getNotifications(value === 1));
    };

    return (
        <Box>
            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                }}
            >
                <Box sx={{ display: "flex", alignItems: "center", gap: 3 }}>
                    <Typography
                        sx={{
                            fontSize: "28px",
                            color: enerbitColors.primary.main,
                            fontWeight: 700,
                        }}
                    >
                        Notificaciones
                    </Typography>
                </Box>
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        gap: 1,
                    }}
                >
                    <Filter onlyNoReaded={value === 1} />
                    <Button
                        onClick={handleRefresh}
                        variant="contained"
                        color="primary"
                        sx={refreshButtonStyles}
                    >
                        <CachedIcon />
                    </Button>
                </Box>
            </Box>
            <Box mt={2}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                    <StyledTabs value={value} onChange={handleChange}>
                        <StyledTab label={<Box>Todas</Box>} {...a11yProps(0)} />
                        <StyledTab
                            label={<Box>No leídas</Box>}
                            {...a11yProps(1)}
                        />
                    </StyledTabs>
                </Box>
                <TabPanel value={value} index={0}>
                    <NotificationsList onlyNoReaded={false} />
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <NotificationsList onlyNoReaded={true} />
                </TabPanel>
            </Box>
        </Box>
    );
};

export default Notifications;

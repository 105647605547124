import {
    Typography,
    enerbitColors,
    Box,
    Divider,
    Grid,
    CircularProgress,
    CustomAlert,
    IconButton,
    Tooltip,
} from "@enerbit/base";
import SectionContainer from "../../../components/container/section-container";
import { MeterGeneralInfo } from "../../../models";
import { useMetersState } from "../../../store";
import { useEffect, useState } from "react";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

interface Props {
    id: string;
}

const generalInfoData: { label: string; key: keyof MeterGeneralInfo }[] = [
    { label: "Serial", key: "serial" },
    { label: "Modelo", key: "model" },
    { label: "Marca", key: "brand" },
    { label: "Protocolo de comunicación", key: "communicationProtocol" },
];

const GeneralInfo = ({ id }: Props) => {
    const { loading, error, meter, getMeter } = useMetersState(
        (state) => state,
    );

    const [copied, setCopied] = useState(false); // Nuevo estado para controlar el ícono

    const handleCopy = (text: string) => {
        navigator.clipboard.writeText(text);
        setCopied(true);
        setTimeout(() => {
            setCopied(false);
        }, 2000);
    };

    useEffect(() => {
        getMeter(id);
    }, [id]);

    return (
        <>
            <SectionContainer>
                <Typography
                    sx={{
                        fontWeight: 700,
                        color: enerbitColors.primary.main,
                        fontSize: "18px",
                    }}
                >
                    Información
                </Typography>
                <Divider sx={{ my: 1, mx: "-12px" }} />
                <Box>
                    {!loading && meter && !error && (
                        <Grid container rowSpacing={2} columnSpacing={2}>
                            {generalInfoData.map((info) => (
                                <Grid item xs={3} key={info.key}>
                                    <Box
                                        sx={{
                                            display: "flex",
                                            alignItems: "center",
                                            gap: 0.5,
                                        }}
                                    >
                                        <Tooltip
                                            title={
                                                copied ? "Copiado" : "Copiar"
                                            }
                                            arrow
                                            placement="top"
                                        >
                                            <IconButton
                                                size="small"
                                                onClick={() => {
                                                    handleCopy(meter[info.key]);
                                                }}
                                            >
                                                <ContentCopyIcon fontSize="small" />
                                            </IconButton>
                                        </Tooltip>
                                        <Box>
                                            <Typography
                                                sx={{
                                                    color: enerbitColors.neutral
                                                        .main,
                                                    fontSize: "12px",
                                                }}
                                            >
                                                {info.label}
                                            </Typography>
                                            <Typography
                                                sx={{
                                                    fontWeight: 700,
                                                    color: enerbitColors
                                                        .neutral[900],
                                                    wordWrap: "break-word",
                                                    wordBreak: "break-word",
                                                }}
                                            >
                                                {meter[info.key]}
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Grid>
                            ))}
                        </Grid>
                    )}
                    {loading && (
                        <Box
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                            }}
                        >
                            <CircularProgress />
                        </Box>
                    )}
                    {!loading && error && (
                        <CustomAlert
                            text="Error buscando información general del medidor."
                            severity="error"
                            onClose={() => {}}
                        />
                    )}
                </Box>
            </SectionContainer>
        </>
    );
};

export default GeneralInfo;

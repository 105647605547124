import {
    Box,
    Button,
    SxProps,
    Typography,
    WarningIcon,
    enerbitColors,
    styled,
} from "@enerbit/base";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { NOTIFICATIONS_COLORS, TOPIC_ROUTES } from "../../common/notifications";
import { Notification, TopicCode } from "../../models/nofitications";
import moment from "moment";
import { navigateToUrl } from "single-spa";
import { updateNotificationStatus } from "../../services/notifications";

interface Props {
    notification: Notification;
}

const getIcon = (name: string, styles: SxProps) => {
    const icons: Record<string, any> = {
        ErrorOutlineIcon: <ErrorOutlineIcon fontSize="small" sx={styles} />,
        WarningIcon: <WarningIcon fontSize="small" sx={styles} />,
    };

    return icons[name];
};

const formatRoute = (param: string, code: TopicCode) => {
    const TOPIC_ROUTES: Record<TopicCode, string> = {
        [TopicCode.NEW_ORDER]: `#/orders/${param}`,
        [TopicCode.ORDER_CANCELATION]: `#/orders/${param}`,
        [TopicCode.PARTIAL_ORDER_DONE]: `#/orders/${param}`,
    };

    return TOPIC_ROUTES[code];
};

const NotificationItem = ({ notification }: Props) => {
    const { level, created_at, body, subject, topic, id } = notification;

    const handleAction = async () => {
        if (!topic) return;

        const { code } = topic;
        const path = TOPIC_ROUTES[code];
        try {
            await updateNotificationStatus(id, true);
            navigateToUrl(formatRoute(notification.metadata.order_id, code));
        } catch (error) {
            navigateToUrl(formatRoute(notification.metadata.order_id, code));
            console.log(error);
        }
    };
    return (
        <Box
            sx={{
                padding: "16px 24px 16px 24px",
                display: "flex",
                justifyContent: "space-between",
            }}
        >
            <Box
                sx={{
                    display: "flex",
                    gap: 2,
                    justifyContent: "space-between",
                }}
            >
                <NotificationIcon
                    bgcolor={NOTIFICATIONS_COLORS[level.toUpperCase()].bgcolor}
                >
                    {getIcon(
                        NOTIFICATIONS_COLORS[level.toUpperCase()].iconName,
                        {
                            color: NOTIFICATIONS_COLORS[level.toUpperCase()]
                                .color,
                        },
                    )}
                </NotificationIcon>
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "3px",
                    }}
                >
                    <Typography sx={{ fontSize: "16px", fontWeight: 700 }}>
                        {subject}
                    </Typography>
                    <Typography sx={{ color: enerbitColors.neutral.main }}>
                        {body}
                    </Typography>
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={handleAction}
                        sx={{
                            minHeight: "unset !important",
                            height: "33px !important",
                            width: "100px",
                            fontSize: "12px",
                        }}
                    >
                        Ver ahora
                    </Button>
                </Box>
            </Box>
            <Typography
                sx={{ fontSize: "12px", color: enerbitColors.neutral.main }}
            >
                {moment(created_at).format("YYYY-MM-DD")}
            </Typography>
        </Box>
    );
};

export default NotificationItem;

interface IconProps {
    bgcolor: string;
}
const NotificationIcon = styled(Box)<IconProps>(({ bgcolor }) => ({
    backgroundColor: bgcolor,
    width: "32px",
    height: "32px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "50%",
}));

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
    NotificationsState,
    SearchNotificationsParams,
} from "../../models/nofitications";
import {
    getNotifications,
    getUserTopics,
    searchTopics,
} from "../thunks/notifications";

const initialState: NotificationsState = {
    notifications: [],
    topics: [],
    userTopics: [],
    currentPage: 0,
    nextPage: 0,
    totalPages: 0,
    loading: false,
    error: false,
    searchParams: {},
    errorTopics: false,
    errorUserTopics: false,
    loadingUserTopics: false,
    loadingTopics: false,
};

export const notificationsSlice = createSlice({
    name: "notificationsSlice",
    initialState,
    reducers: {
        setCurrentPage: (state, { payload }: PayloadAction<number>) => {
            state.currentPage = payload;
        },
        setSearchParams: (
            state,
            {
                payload,
            }: PayloadAction<{
                key: keyof SearchNotificationsParams;
                value: any;
            }>,
        ) => {
            state.searchParams[payload.key] = payload.value;
        },
        resetSearchParams: (state) => {
            state.searchParams = {};
        },
        appendUserTopic: (state, { payload }: PayloadAction<string>) => {
            state.userTopics.push(payload);
        },
        removeUserTopic: (state, { payload }: PayloadAction<string>) => {
            state.userTopics = state.userTopics.filter(
                (item) => item !== payload,
            );
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getNotifications.pending, (state) => {
            state.error = false;
            state.loading = true;
        });
        builder.addCase(getNotifications.fulfilled, (state, { payload }) => {
            state.error = false;
            state.loading = false;
            state.notifications = payload.notifications;
            state.currentPage = payload.currentPage;
            state.totalPages = payload.totalPages;
        });
        builder.addCase(getNotifications.rejected, (state, { payload }) => {
            state.error = true;
            state.loading = false;
        });
        builder.addCase(searchTopics.pending, (state) => {
            state.loadingTopics = true;
            state.errorTopics = false;
        });
        builder.addCase(searchTopics.fulfilled, (state, { payload }) => {
            state.loadingTopics = false;
            state.errorTopics = false;
            state.topics = payload?.topics ?? [];
        });
        builder.addCase(searchTopics.rejected, (state) => {
            state.loadingTopics = false;
            state.errorTopics = true;
        });
        builder.addCase(getUserTopics.pending, (state) => {
            state.loadingUserTopics = true;
            state.errorUserTopics = false;
        });
        builder.addCase(getUserTopics.fulfilled, (state, { payload }) => {
            state.loadingUserTopics = false;
            state.errorUserTopics = false;
            state.userTopics = payload ?? [];
        });
        builder.addCase(getUserTopics.rejected, (state) => {
            state.loadingUserTopics = false;
            state.errorUserTopics = true;
        });
    },
});

export const {
    setCurrentPage,
    setSearchParams,
    resetSearchParams,
    removeUserTopic,
    appendUserTopic,
} = notificationsSlice.actions;

export default notificationsSlice.reducer;

import { HashRouter, Route, Routes } from "react-router-dom";
import Notifications from "../pages/Notifications";

const Router = () => {
    return (
        <HashRouter basename="/notifications-history">
            <Routes>
                <Route path="/" element={<Notifications />} />
                {/* <Route path='/settings' element={<Settings />} /> */}
            </Routes>
        </HashRouter>
    );
};

export default Router;

import {
    Box,
    Button,
    Grid,
    SxProps,
    Typography,
    WarningIcon,
    enerbitColors,
    styled,
} from "@enerbit/base";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { NOTIFICATIONS_COLORS, TOPIC_ROUTES } from "../../common/notifications";
import { Notification, TopicCode } from "../../models/nofitications";
import { navigateToUrl } from "single-spa";
import moment from "moment";
import { updateNotificationStatus } from "../../services/notifications";

interface Props {
    notification: Notification;
}

const getIcon = (name: string, styles: SxProps) => {
    const icons: Record<string, any> = {
        ErrorOutlineIcon: <ErrorOutlineIcon fontSize="small" sx={styles} />,
        WarningIcon: <WarningIcon fontSize="small" sx={styles} />,
    };

    return icons[name];
};

const formatRoute = (param: string, code: TopicCode) => {
    const TOPIC_ROUTES: Record<TopicCode, string> = {
        [TopicCode.NEW_ORDER]: `#/orders/${param}`,
        [TopicCode.ORDER_CANCELATION]: `#/orders/${param}`,
        [TopicCode.PARTIAL_ORDER_DONE]: `#/orders/${param}`,
    };

    return TOPIC_ROUTES[code];
};

const NotificationItem = ({ notification }: Props) => {
    const { level, body, subject, created_at, topic, id } = notification;

    const handleAction = async () => {
        if (!topic) return;

        const { code } = topic;
        const path = TOPIC_ROUTES[code];

        try {
            await updateNotificationStatus(id, true);
            navigateToUrl(formatRoute(notification.metadata.order_id, code));
        } catch (error) {
            navigateToUrl(formatRoute(notification.metadata.order_id, code));
        }
    };

    return (
        <Grid container columnSpacing={2}>
            <Grid item xs={6}>
                <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                    <NotificationIcon
                        bgcolor={
                            NOTIFICATIONS_COLORS[level.toUpperCase()].bgcolor
                        }
                    >
                        {getIcon(
                            NOTIFICATIONS_COLORS[level.toUpperCase()].iconName,
                            {
                                color: NOTIFICATIONS_COLORS[level.toUpperCase()]
                                    .color,
                            },
                        )}
                    </NotificationIcon>
                    <Box>
                        <Typography sx={{ fontSize: "16px", fontWeight: 700 }}>
                            {subject}
                        </Typography>
                        <Typography sx={{ color: enerbitColors.neutral.main }}>
                            {body}
                        </Typography>
                    </Box>
                </Box>
            </Grid>
            <Grid item xs={3} style={{ display: "flex", alignItems: "center" }}>
                <Typography sx={{ color: enerbitColors.neutral.main }}>
                    {moment(created_at).format("YYYY-MM-DD HH:mm")}
                </Typography>
            </Grid>
            <Grid
                item
                xs={3}
                style={{
                    display: "flex",
                    justifyContent: "end",
                    alignItems: "center",
                }}
            >
                <Button
                    variant="contained"
                    color="secondary"
                    onClick={handleAction}
                    sx={{
                        minHeight: "unset !important",
                        height: "33px",
                        fontSize: "12px",
                    }}
                >
                    Ver ahora
                </Button>
            </Grid>
        </Grid>
    );
};

export default NotificationItem;

interface IconProps {
    bgcolor: string;
}
const NotificationIcon = styled(Box)<IconProps>(({ bgcolor }) => ({
    backgroundColor: bgcolor,
    width: "32px",
    height: "32px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "50%",
}));

import { create } from "zustand";
import { MetersState, SearchMetersParams } from "../models";
import {
    getMeterModelsService,
    getMeterService,
    searchMetersService,
} from "../services";

export const useMetersState = create<MetersState>()((set) => ({
    loading: false,
    error: false,
    meters: [],
    meter: null,
    metetModels: [],
    currentPage: 0,
    totalMeters: 0,
    totalPages: 0,
    searchMeters: async ({ page, filters }: SearchMetersParams) => {
        set({ loading: true, error: false });
        try {
            const res = await searchMetersService({ page, filters });

            set({
                meters: res.items,
                currentPage: res.page,
                totalMeters: res.total,
                totalPages: res.pages,
            });
        } catch (_error) {
            set({ error: true });
        } finally {
            set({ loading: false });
        }
    },
    getMeterModels: async () => {
        try {
            const res = await getMeterModelsService();
            set({ metetModels: res.items });
        } catch (_error) {}
    },
    getMeter: async (id: string) => {
        set({ loading: true, error: false, meter: null });
        try {
            const res = await getMeterService(id);

            set({
                meter: {
                    serial: res.serial,
                    brand: res.meter_model.brand.name,
                    model: res.meter_model.name,
                    communicationProtocol:
                        res.meter_model.communication_protocol_type.name,
                },
            });
        } catch (_error) {
            set({ error: true, meter: null });
        } finally {
            set({ loading: false });
        }
    },
}));

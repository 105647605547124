import {
    Box,
    CircularProgress,
    CustomAlert,
    Grid,
    Pagination,
    Stack,
    Typography,
    enerbitColors,
} from "@enerbit/base";
import NotificationsContainer from "./NotificationContainer";
import NotificationItem from "./NotificationItem";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store/store";
import { useEffect } from "react";
import { getNotifications } from "../../store/thunks/notifications";
import PaginationContainer from "../PaginationContainer/PaginationContainer";
import {
    resetSearchParams,
    setCurrentPage,
    setSearchParams,
} from "../../store/slices/notificationsSlice";

interface Props {
    onlyNoReaded: boolean;
}

const NotificationsList = ({ onlyNoReaded }: Props) => {
    const { currentPage, totalPages, notifications, error, loading } =
        useSelector((state: RootState) => state.notifications);

    const dispatch = useDispatch<AppDispatch>();

    const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
        event.preventDefault();
        dispatch(setCurrentPage(value - 1));
        dispatch(getNotifications(onlyNoReaded));
    };

    useEffect(() => {
        dispatch(resetSearchParams());
        dispatch(getNotifications(onlyNoReaded));
    }, []);

    return (
        <Box mt={3}>
            {notifications.length > 0 && !loading && !error && (
                <>
                    <Grid container columnSpacing={2}>
                        <Grid item xs={6}>
                            <Typography
                                sx={{
                                    color: enerbitColors.primary.main,
                                    fontWeight: 700,
                                }}
                            >
                                Tipo de notificación
                            </Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <Typography
                                sx={{
                                    color: enerbitColors.primary.main,
                                    fontWeight: 700,
                                }}
                            >
                                Fecha y hora
                            </Typography>
                        </Grid>
                        <Grid item xs={3}></Grid>
                    </Grid>
                    {notifications.map((notification) => (
                        <NotificationsContainer key={notification.id} mt={2}>
                            <NotificationItem notification={notification} />
                        </NotificationsContainer>
                    ))}
                    <PaginationContainer>
                        <Stack spacing={2}>
                            <Pagination
                                page={currentPage + 1}
                                count={totalPages}
                                shape="rounded"
                                onChange={handleChange}
                            />
                        </Stack>
                    </PaginationContainer>
                </>
            )}
            {loading && (
                <Box
                    my={2}
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                    <CircularProgress />
                </Box>
            )}
            {error && (
                <CustomAlert
                    text="Error cargando las notificaciones."
                    severity="error"
                    onClose={() => {}}
                />
            )}
            {notifications.length === 0 && !error && !loading && (
                <CustomAlert
                    text="No hay notificaciones para ver."
                    severity="info"
                    onClose={() => {}}
                />
            )}
        </Box>
    );
};

export default NotificationsList;

import { Box, IconButton, Typography, enerbitColors } from "@enerbit/base";
import CloseIcon from "@mui/icons-material/Close";
import React, { useState } from "react";
import { StyledTab } from "../components/StyledTab/StyledTab";
import { StyledTabs } from "../components/StyledTabs/StyledTabs";
import { TabPanel } from "../components/TabPanel/TabPanel";
import NotificationsList from "../components/NotificationsList/NotificationsList";
import { navigateToUrl } from "single-spa";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../store/store";
import { setCurrentPage } from "../store/slices/notificationsSlice";
import SettingsIcon from "@mui/icons-material/Settings";
import SettingsModal from "../components/Settings/SettingsModal";

interface Props {
    handleClose: () => void;
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}

const Notifications = ({ handleClose }: Props) => {
    const [tabValue, setTabValue] = useState<number>(0);
    const [openSettingModal, setOpenSettingModal] = useState<boolean>(false);

    const dispatch = useDispatch<AppDispatch>();

    const handleTabChange = (
        _event: React.SyntheticEvent,
        newValue: number,
    ) => {
        dispatch(setCurrentPage(0));
        setTabValue(newValue);
    };

    const goToSettings = () => {
        navigateToUrl("#/notifications-history/settings");
    };

    return (
        <>
            <Box>
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                    }}
                >
                    <Typography
                        sx={{
                            color: enerbitColors.primary.main,
                            fontWeight: 700,
                            fontSize: "24px",
                        }}
                    >
                        Notificaciones
                    </Typography>
                    <IconButton
                        color="inherit"
                        onClick={() => setOpenSettingModal(true)}
                    >
                        <SettingsIcon />
                    </IconButton>
                </Box>
                <Box mt={1}>
                    <Box
                        sx={{
                            borderBottom: 1,
                            borderColor: "divider",
                            marginLeft: "-24px",
                            marginRight: "-24px",
                        }}
                    >
                        <StyledTabs value={tabValue} onChange={handleTabChange}>
                            <StyledTab
                                label={<Box>Todas</Box>}
                                {...a11yProps(0)}
                            />
                            <StyledTab
                                label={<Box>No leídas</Box>}
                                {...a11yProps(1)}
                            />
                            {/* <StyledTab label={<Box>Configuración</Box>} {...a11yProps(2)} /> */}
                        </StyledTabs>
                    </Box>
                    {tabValue !== 2 && (
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "flex-end",
                                alignItems: "center",
                                my: 2,
                            }}
                        >
                            <Typography
                                onClick={() => {
                                    navigateToUrl("#/notifications-history");
                                    handleClose();
                                }}
                                sx={{
                                    color: enerbitColors.primary.main,
                                    fontWeight: 700,
                                    cursor: "pointer",
                                    "&:hover": { textDecoration: "underline" },
                                }}
                            >
                                Ver todo
                            </Typography>
                        </Box>
                    )}
                    <TabPanel value={tabValue} index={0}>
                        <NotificationsList onlyNoRead={false} />
                    </TabPanel>
                    <TabPanel value={tabValue} index={1}>
                        <NotificationsList onlyNoRead={true} />
                    </TabPanel>
                    {/* <TabPanel value={tabValue} index={2}>
          <Settings />
        </TabPanel> */}
                </Box>
            </Box>
            <SettingsModal
                open={openSettingModal}
                handleClose={() => setOpenSettingModal(false)}
            />
        </>
    );
};

export default Notifications;

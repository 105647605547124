import { Box, enerbitColors, Typography } from "@enerbit/base";
import { useState } from "react";
import { StyledTab } from "../../components/Tabs/styled-tab";
import { StyledTabs } from "../../components/Tabs/styled-tabs";
import { TabPanel } from "../../components/Tabs/tab-panel";
import MetersTable from "./components/meters-table";

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}

const MetersList = () => {
    const [value, setValue] = useState<number>(0);

    const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    return (
        <Box>
            <Typography
                sx={{
                    color: enerbitColors.primary.main,
                    fontWeight: 700,
                    fontSize: "28px",
                }}
            >
                Medidores
            </Typography>
            <Box
                sx={{
                    borderBottom: 1,
                    borderColor: "divider",
                }}
            >
                <StyledTabs value={value} onChange={handleChange}>
                    <StyledTab
                        label={<Box>Lista medidores</Box>}
                        {...a11yProps(0)}
                    />
                    {/* <StyledTab
                        label={<Box>Historial de eventos</Box>}
                        {...a11yProps(1)}
                    /> */}
                </StyledTabs>
            </Box>
            <TabPanel value={value} index={0}>
                <Box sx={{ mt: 3 }}>
                    <MetersTable />
                </Box>
            </TabPanel>
            {/* <TabPanel value={value} index={1}>
                <Box sx={{ mt: 3 }}>
                    <EventsTable />
                </Box>
            </TabPanel> */}
        </Box>
    );
};

export default MetersList;

import { Box } from "@enerbit/base";

interface Props {
    children?: any;
    index: number;
    value: number;
}

export const TabPanel = ({ children, value, index, ...other }: Props) => {
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box>{children}</Box>}
        </div>
    );
};

import {
    Box,
    Button,
    DialogContent,
    FormControl,
    Grid,
    InputLabel,
    TextField,
} from "@enerbit/base";
import { zodResolver } from "@hookform/resolvers/zod";
import type { FC } from "react";
import { Controller, useForm } from "react-hook-form";
import { StyledDialog, StyledDialogTitle } from "../../common";
import { transformMaterial } from "../../helpers/material-labor";
import type { IMaterialQuotationFormData } from "../../interfaces";
import { createMaterialQuotationSchema } from "../../schemas";
import { useEmissionStore } from "../../store";

interface Props {
    open: boolean;
    handleClose: () => void;
}

export const AddMaterialQuotationModal: FC<Props> = ({ open, handleClose }) => {
    const {
        material_quotation,
        addMaterialQuotation,
        updateQuantityMaterialQuotation,
    } = useEmissionStore();

    const {
        control,
        handleSubmit,
        formState: { errors },
    } = useForm<IMaterialQuotationFormData>({
        resolver: zodResolver(createMaterialQuotationSchema),
    });

    const onSubmit = (data: IMaterialQuotationFormData) => {
        const existingMaterial = material_quotation.find(
            (mat) => mat.name.toLowerCase() === data.material.toLowerCase(),
        );

        if (existingMaterial) {
            const updatedQuantity =
                Number(existingMaterial.quantity) + Number(data.quantity);

            updateQuantityMaterialQuotation(
                existingMaterial.id,
                updatedQuantity,
            );
        } else {
            const newMaterial = transformMaterial({
                name: data.material,
                charge: "0", // Ahora utilizamos el valor ingresado para el precio del material
                quantity: Number(data.quantity ?? 1),
                unit: "", // Puedes definir una unidad predeterminada si es necesario
                description: data.description,
                item_code: "",
            });
            addMaterialQuotation(newMaterial);
        }
        handleClose();
    };

    return (
        <StyledDialog
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            open={open}
            fullWidth
        >
            <StyledDialogTitle
                id="customized-dialog-title"
                onClose={handleClose}
            >
                Agregar un nuevo material
            </StyledDialogTitle>
            <DialogContent>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <InputLabel>Material</InputLabel>
                            <FormControl fullWidth>
                                <Controller
                                    name="material"
                                    control={control}
                                    render={({ field }) => (
                                        <TextField
                                            {...field}
                                            placeholder="Nombre del material"
                                            fullWidth
                                            error={!!errors.material}
                                            helperText={
                                                errors.material?.message
                                            }
                                            sx={{
                                                "& fieldset": {
                                                    borderRadius: "14px",
                                                },
                                            }}
                                        />
                                    )}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <InputLabel>Cantidad</InputLabel>
                            <FormControl fullWidth>
                                <Controller
                                    name="quantity"
                                    control={control}
                                    render={({ field }) => (
                                        <TextField
                                            {...field}
                                            InputProps={{
                                                inputProps: {
                                                    min: 1,
                                                    max: 10,
                                                },
                                            }}
                                            type="number"
                                            placeholder="Cantidad"
                                            fullWidth
                                            error={!!errors.quantity}
                                            helperText={
                                                errors.quantity?.message
                                            }
                                            sx={{
                                                "& fieldset": {
                                                    borderRadius: "14px",
                                                },
                                            }}
                                        />
                                    )}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <InputLabel>Descripción</InputLabel>
                            <FormControl fullWidth>
                                <Controller
                                    name="description"
                                    control={control}
                                    render={({ field }) => (
                                        <TextField
                                            {...field}
                                            placeholder="Descripción del material"
                                            fullWidth
                                            multiline
                                            rows={3}
                                            sx={{
                                                "& fieldset": {
                                                    borderRadius: "14px",
                                                },
                                            }}
                                        />
                                    )}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <Box
                                sx={{
                                    width: "100%",
                                    display: "flex",
                                    justifyContent: "center",
                                }}
                            >
                                <Button
                                    color="primary"
                                    variant="contained"
                                    type="submit"
                                >
                                    Agregar
                                </Button>
                            </Box>
                        </Grid>
                    </Grid>
                </form>
            </DialogContent>
        </StyledDialog>
    );
};

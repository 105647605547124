import { Box, Grid } from "@enerbit/base";
import { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import SectionContainer from "../../components/container/section-container";
import DetailHeader from "./components/detail-header";
import GeneralInfo from "./components/general-info";
import MultipliersHistory from "./components/multipliers-history";
import MetersRelationships from "./components/meters-relationships";
import MeasurementPointHistory from "./components/measurement-point-history";
import MeasurementOverview from "./components/measurements-overview";
import OrdersHistory from "./components/orders-history";
import { StyledTab } from "../../components/Tabs/styled-tab";
import { StyledTabs } from "../../components/Tabs/styled-tabs";
import { TabPanel } from "../../components/Tabs/tab-panel";
import MeterEvents from "./components/meter-events";
import MeterUsages from "./components/meter-usages";
import Actions from "./components/actions";

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}

const MeterDetail = () => {
    const { id } = useParams<{ id: string }>();
    const [value, setValue] = useState<number>(0);

    const navigate = useNavigate();

    const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    useEffect(() => {
        if (!id) navigate("/");
    }, []);

    return (
        <>
            {id && (
                <Box>
                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                        }}
                    >
                        <DetailHeader id={id} />
                        <Actions />
                    </Box>
                    <Grid container columnSpacing={3} sx={{ mt: 3 }}>
                        <Grid item xs={8.5}>
                            <Box>
                                <GeneralInfo id={id} />
                            </Box>
                            <Box sx={{ mt: 2 }}>
                                <MultipliersHistory id={id} />
                            </Box>
                            <Box sx={{ mt: 2 }}>
                                <MeterUsages id={id} />
                            </Box>
                            <SectionContainer sx={{ mt: 2 }}>
                                <Box>
                                    <Box
                                        sx={{
                                            borderBottom: 1,
                                            borderColor: "divider",
                                        }}
                                    >
                                        <StyledTabs
                                            value={value}
                                            onChange={handleChange}
                                        >
                                            <StyledTab
                                                label={<Box>Historial de eventos</Box>}
                                                {...a11yProps(0)}
                                            />
                                            <StyledTab
                                                label={
                                                    <Box>
                                                        Historial órdenes de
                                                        servicio
                                                    </Box>
                                                }
                                                {...a11yProps(1)}
                                            />
                                        </StyledTabs>
                                    </Box>
                                    <TabPanel value={value} index={0}>
                                        <Box sx={{ my: 2 }}>
                                            <MeterEvents id={id} />
                                        </Box>
                                    </TabPanel>
                                    <TabPanel value={value} index={1}>
                                        <Box sx={{ my: 2 }}>
                                            <OrdersHistory id={id} />
                                        </Box>
                                    </TabPanel>
                                </Box>
                            </SectionContainer>
                        </Grid>
                        <Grid item xs={3.5}>
                            <Box sx={{ mb: 2 }}>
                                <MeasurementOverview id={id} />
                            </Box>
                            <Box sx={{ mb: 2 }}>
                                <MeasurementPointHistory id={id} />
                            </Box>
                            <Box sx={{ mb: 2 }}>
                                <MetersRelationships id={id} />
                            </Box>
                            {/* <Box sx={{ mb: 2 }}>
                                <FasorialDiagram id={id} />
                            </Box> */}
                        </Grid>
                    </Grid>
                </Box>
            )}
        </>
    );
};

export default MeterDetail;

import {
    Box,
    Button,
    CircularProgress,
    DialogContent,
    FormControl,
    Grid,
    InputAdornment,
    InputLabel,
    MenuItem,
    Select,
} from "@enerbit/base";
import { zodResolver } from "@hookform/resolvers/zod";
import { type FC, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { StyledDialog, StyledDialogTitle } from "../../common";
import { convertMoneyToString } from "../../helpers";
import { transformMaterial } from "../../helpers/material-labor";
import type { IMaterialFormData, keyMaterialsSolar } from "../../interfaces";
import { createMaterialSchema } from "../../schemas";
import { useEmissionStore } from "../../store";
import { useEmissionStoreSolar } from "../../store/emission-solar";

interface Props {
    open: boolean;
    isSolar?: boolean;
    keyDataSolar?: keyMaterialsSolar;
    handleClose: () => void;
}

export const AddMaterialModal: FC<Props> = ({
    open,
    handleClose,
    isSolar,
    keyDataSolar,
}) => {
    const {
        materials,
        listCategories,
        listMaterials,
        isLoadingMaterials,
        listQuantities,
        getMaterials,
        addMaterial,
        updateQuantityMaterial,
        getCategory,
    } = useEmissionStore();

    const { addMaterialSolar, updateQuantityMaterialSolar } =
        useEmissionStoreSolar();

    const { control, handleSubmit, watch } = useForm<IMaterialFormData>({
        resolver: zodResolver(createMaterialSchema),
    });

    const onSubmit = (data: IMaterialFormData) => {
        const materialInfo = listMaterials.find(
            (material) =>
                material["Texto breve material"] === data.selectedMaterial,
        );
        if (materialInfo) {
            const materialName = materialInfo["Texto breve material"];
            const existingMaterial = materials.find(
                (mat) => mat.name.toLowerCase() === materialName.toLowerCase(),
            );

            if (existingMaterial) {
                const updatedQuantity =
                    Number(existingMaterial.quantity) +
                    Number(data.selectedQuantity);

                isSolar && keyDataSolar
                    ? updateQuantityMaterialSolar(
                          existingMaterial.id,
                          updatedQuantity,
                          keyDataSolar,
                      )
                    : updateQuantityMaterial(
                          existingMaterial.id,
                          updatedQuantity,
                      );
            } else {
                const newMaterial = transformMaterial({
                    name: materialName,
                    charge: materialInfo["Valor unitario con IVA"]
                        ? convertMoneyToString(
                              materialInfo["Valor unitario con IVA"],
                          )
                        : "0",
                    quantity: Number(data.selectedQuantity ?? 1),
                    unit: materialInfo["Unidad de medida"],
                    item_code: materialInfo["item_code"],
                });
                isSolar && keyDataSolar
                    ? addMaterialSolar(newMaterial, keyDataSolar)
                    : addMaterial(newMaterial);
            }
            handleClose();
        }
    };

    useEffect(() => {
        const selectedCategory = watch("selectedCategory");
        if (selectedCategory) {
            getMaterials(selectedCategory);
        }
    }, [watch("selectedCategory"), getMaterials]);

    useEffect(() => {
        getCategory();
    }, []);

    return (
        <StyledDialog
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            open={open}
            fullWidth
        >
            <StyledDialogTitle
                id="customized-dialog-title"
                onClose={handleClose}
            >
                Ingresar un nuevo registro
            </StyledDialogTitle>
            <DialogContent>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <InputLabel>Categoría</InputLabel>
                            <FormControl fullWidth>
                                <Controller
                                    name="selectedCategory"
                                    control={control}
                                    render={({ field }) => (
                                        <Select
                                            {...field}
                                            disabled={isLoadingMaterials}
                                            displayEmpty
                                            sx={{
                                                "& fieldset": {
                                                    borderRadius: "14px",
                                                },
                                            }}
                                        >
                                            {listCategories?.map(
                                                (item, index) => (
                                                    <MenuItem
                                                        value={item}
                                                        key={index.toString()}
                                                    >
                                                        {item}
                                                    </MenuItem>
                                                ),
                                            )}
                                        </Select>
                                    )}
                                />
                            </FormControl>
                        </Grid>
                        {watch("selectedCategory") && (
                            <Grid item xs={12}>
                                <InputLabel>Material</InputLabel>
                                <FormControl fullWidth>
                                    <Controller
                                        name="selectedMaterial"
                                        control={control}
                                        render={({ field }) => (
                                            <Select
                                                {...field}
                                                displayEmpty
                                                disabled={isLoadingMaterials}
                                                sx={{
                                                    "& fieldset": {
                                                        borderRadius: "14px",
                                                    },
                                                    pr: isLoadingMaterials
                                                        ? 3
                                                        : 0,
                                                }}
                                                endAdornment={
                                                    isLoadingMaterials ? (
                                                        <InputAdornment position="start">
                                                            <CircularProgress
                                                                size={20}
                                                                thickness={6}
                                                            />
                                                        </InputAdornment>
                                                    ) : null
                                                }
                                            >
                                                {listMaterials.map(
                                                    (item, index) => (
                                                        <MenuItem
                                                            value={
                                                                item[
                                                                    "Texto breve material"
                                                                ]
                                                            }
                                                            key={index.toString()}
                                                        >
                                                            {
                                                                item[
                                                                    "Texto breve material"
                                                                ]
                                                            }
                                                        </MenuItem>
                                                    ),
                                                )}
                                            </Select>
                                        )}
                                    />
                                </FormControl>
                            </Grid>
                        )}
                        {watch("selectedMaterial") && (
                            <Grid item xs={12}>
                                <InputLabel>Cantidad</InputLabel>
                                <FormControl fullWidth>
                                    <Controller
                                        name="selectedQuantity"
                                        control={control}
                                        render={({ field }) => (
                                            <Select
                                                {...field}
                                                displayEmpty
                                                sx={{
                                                    "& fieldset": {
                                                        borderRadius: "14px",
                                                    },
                                                }}
                                            >
                                                {listQuantities.map(
                                                    (item, index) => (
                                                        <MenuItem
                                                            value={item}
                                                            key={index.toString()}
                                                        >
                                                            {item}
                                                        </MenuItem>
                                                    ),
                                                )}
                                            </Select>
                                        )}
                                    />
                                </FormControl>
                            </Grid>
                        )}
                        <Grid item xs={12}>
                            <Box
                                sx={{
                                    width: "100%",
                                    display: "flex",
                                    justifyContent: "center",
                                }}
                            >
                                <Button
                                    color="primary"
                                    variant="contained"
                                    disabled={!watch("selectedQuantity")}
                                    type="submit"
                                >
                                    Agregar
                                </Button>
                            </Box>
                        </Grid>
                    </Grid>
                </form>
            </DialogContent>
        </StyledDialog>
    );
};

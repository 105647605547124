import { hot } from "react-hot-loader/root";
import MainLayout from "./layouts/main-layout";
import { initEnviroment, ThemeConfig } from "@enerbit/base";
import MetersRouter from "./router/router";

import { PrimeReactProvider } from "primereact/api";

initEnviroment({
    baseUrl: process.env.REACT_APP_BASE_URL,
    geopoliticsBaseUrl: process.env.REACT_APP_BASE_GEOPOLITICS_URL,
});

export default hot(function Root() {
    return (
        <ThemeConfig>
            <PrimeReactProvider>
                <MainLayout>
                    <MetersRouter />
                </MainLayout>
            </PrimeReactProvider>
        </ThemeConfig>
    );
});

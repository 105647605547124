import { create } from "zustand";
import { EventTypes, IEvents, Meter, SearchEventsFilters } from "../models";
import {
    getEventsService,
    getEventTypesService,
    getMeterModelsService,
    getMeterService,
} from "../services";

interface EventsStore {
    loading: boolean;
    error: boolean;
    currentPage: number;
    totalPages: number;
    events: IEvents[];
    eventTypes: EventTypes[];
    currentMeter: Meter | null;
    getMeterEvents: (props: { page: number; id: string }) => Promise<void>;
    searchEvents: (
        page: number,
        filters?: SearchEventsFilters,
    ) => Promise<void>;
    getEventTypes: () => Promise<void>;
}

export const useEventsStore = create<EventsStore>()((set, get) => ({
    loading: false,
    error: false,
    currentPage: 0,
    totalPages: 0,
    events: [],
    eventTypes: [],
    currentMeter: null,
    getMeterEvents: async ({ page, id }: { page: number; id: string }) => {
        try {
            set({ loading: true, error: false });

            const state = get();
            let { eventTypes, currentMeter } = state;

            if (eventTypes.length === 0) {
                eventTypes = await getEventTypesService();
                set({ eventTypes });
            }

            if (!currentMeter) {
                currentMeter = await getMeterService(id);
                set({ currentMeter });
            }

            const serial = currentMeter?.serial ?? "";
            const mappedEventTypes = eventTypes.map((eT) => eT.event);

            const events = await getEventsService({
                page,
                filters: {
                    meter_serial: serial,
                    event_types: mappedEventTypes,
                },
            });
            const { items, pages } = events;

            set({
                currentPage: page,
                events: items,
                totalPages: pages,
            });
        } catch (_error) {
            set({ error: true });
        } finally {
            set({ loading: false });
        }
    },
    getEventTypes: async () => {
        try {
            set({ loading: true, error: false });
            const types = await getEventTypesService();
            set({ eventTypes: types });
        } catch (_error) {
            set({ error: true });
        } finally {
            set({ loading: false });
        }
    },
    searchEvents: async (page: number, filters?: SearchEventsFilters) => {
        try {
            set({ loading: true, error: false });

            const state = get();
            let { eventTypes } = state;

            if (eventTypes.length === 0 || !filters?.eventTypes) {
                eventTypes = await getEventTypesService();
                set({ eventTypes });
            }

            const mappedEventTypes = eventTypes.map((eT) => eT.event);
            const models = await getMeterModelsService();

            const events = await getEventsService({
                page: page ?? 0,
                filters: { ...filters, event_types: mappedEventTypes },
            });
            const { items, pages } = events;

            set({
                currentPage: page,
                events: items.map((ev) => ({
                    ...ev,
                    meter_model:
                        models.items.find((m) => ev.meter_model === m.code)
                            ?.name ?? "Desconocido",
                })),
                totalPages: pages,
            });
        } catch (_error) {
            set({ error: true });
        } finally {
            set({ loading: false });
        }
    },
}));

import { enerbitColors } from "@enerbit/base";
import { TopicCode } from "../models/nofitications";

export const NOTIFICATIONS_COLORS: Record<
    string,
    { bgcolor: string; color: string; iconName: string }
> = {
    INFO: {
        bgcolor: "#EAF0FA",
        color: "#3B82F6",
        iconName: "ErrorOutlineIcon",
    },
    ERROR: {
        bgcolor: enerbitColors.error[100] ?? "",
        color: enerbitColors.error.main,
        iconName: "WarningIcon",
    },
    WARNING: {
        bgcolor: enerbitColors.warning[100] ?? "",
        color: enerbitColors.warning.main,
        iconName: "ErrorOutlineIcon",
    },
};

export const TOPIC_ROUTES: Record<TopicCode, string> = {
    [TopicCode.NEW_ORDER]: "#/orders",
    [TopicCode.ORDER_CANCELATION]: "#/orders",
    [TopicCode.PARTIAL_ORDER_DONE]: "#/orders",
};

import {
    Box,
    Button,
    CircularProgress,
    CustomAlert,
    Divider,
    enerbitColors,
    Typography,
} from "@enerbit/base";
import SectionContainer from "../../../components/container/section-container";
import { useMeasurementPointState } from "../../../store";
import { useEffect, useState } from "react";
import MeasurementPointItem from "./measurement-point-item";
import { MeasurementPoint } from "../../../models";
import MeasurementPointModal from "./measurment-point-modal";

interface Props {
    id: string;
}

const sortMeasurementPoints = (a: MeasurementPoint, b: MeasurementPoint) => {
    if (a.ended_at === null && b.ended_at !== null) {
        return -1; // a goes before b
    }
    if (a.ended_at !== null && b.ended_at === null) {
        return 1; // b goes before a
    }
    return 0;
};

const MeasurementPointHistory = ({ id }: Props) => {
    const {
        loading,
        error,
        measurementPoints,
        getMeterMeasurementPoint,
        totalMeasurementPoints,
    } = useMeasurementPointState((state) => state);
    const [open, setOpen] = useState<boolean>(false);

    useEffect(() => {
        getMeterMeasurementPoint({ meterId: id, page: 0 });
    }, [id]);

    return (
        <SectionContainer>
            <Typography
                sx={{
                    fontWeight: 700,
                    color: enerbitColors.primary.main,
                    fontSize: "18px",
                }}
            >
                Histórico punto de medida
            </Typography>
            <Divider sx={{ my: 1, mx: "-12px" }} />
            {loading && (
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                    <CircularProgress />
                </Box>
            )}
            {!loading && error && (
                <CustomAlert
                    text="Error cargando puntos de medida."
                    severity="error"
                    onClose={() => {}}
                />
            )}
            {!loading && !error && measurementPoints.length > 0 && (
                <>
                    {measurementPoints
                        .slice(0, 3)
                        .sort(sortMeasurementPoints)
                        .map((mp) => (
                            <Box key={mp.id} sx={{ mb: 1 }}>
                                <MeasurementPointItem mp={mp} />
                            </Box>
                        ))}
                    {totalMeasurementPoints > 3 && (
                        <Box
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                mt: 2,
                            }}
                        >
                            <Button
                                variant="text"
                                color="primary"
                                onClick={() => setOpen(true)}
                            >
                                Ver más
                            </Button>
                        </Box>
                    )}
                </>
            )}
            {!loading && !error && measurementPoints.length === 0 && (
                <CustomAlert
                    text="No se encontraron puntos de medida."
                    severity="info"
                    onClose={() => {}}
                />
            )}
            {open && (
                <MeasurementPointModal
                    open={open}
                    handleClose={() => {
                        setOpen(false);
                    }}
                    id={id}
                />
            )}
        </SectionContainer>
    );
};

export default MeasurementPointHistory;

import {
    Autocomplete,
    CircularProgress,
    FormControl,
    Grid,
    InputLabel,
    TextField,
    geopoliticsApi,
} from "@enerbit/base";
import { useEffect, useState } from "react";
import { Controller, get, useFormContext } from "react-hook-form";
import type {
    ICityItem,
    IRespCity,
    IRespState,
    IStateItem,
} from "../../../models";

export const LocationSmartBit = () => {
    const {
        control,
        watch,
        setValue,
        formState: { errors },
    } = useFormContext();
    const [departments, setDepartments] = useState<IStateItem[]>([]);
    const [cities, setCities] = useState<ICityItem[]>([]);
    const [loadingDepartments, setLoadingDepartments] = useState(false);
    const [loadingCities, setLoadingCities] = useState(false);
    const selectedStateName = watch("state");

    // Cargar departamentos
    useEffect(() => {
        const fetchDepartments = async () => {
            setLoadingDepartments(true);
            try {
                const response = await geopoliticsApi.get<IRespState>(
                    "/states/?country_code=co",
                );
                setDepartments(response.data.items);
            } catch (error) {
                console.error(error);
            } finally {
                setLoadingDepartments(false);
            }
        };

        fetchDepartments();
    }, []);

    // Cargar ciudades cuando se selecciona un departamento
    useEffect(() => {
        const fetchCities = async () => {
            if (!selectedStateName) {
                setCities([]);
                return;
            }

            const selectedDepartment = departments.find(
                (dept) => dept.name === selectedStateName,
            );
            if (!selectedDepartment) return;

            setLoadingCities(true);
            try {
                const response = await geopoliticsApi.get<IRespCity>(
                    `/cities/?state_code=${selectedDepartment.state_code}`,
                );
                setCities(response.data.items);
            } catch (error) {
                console.error(error);
            } finally {
                setLoadingCities(false);
            }
        };

        fetchCities();
    }, [selectedStateName, departments]);

    return (
        <Grid container item spacing={3}>
            <Grid item xs={6}>
                <InputLabel>Departamento</InputLabel>
                <FormControl fullWidth>
                    <Controller
                        name="state"
                        control={control}
                        render={({ field }) => (
                            <Autocomplete
                                {...field}
                                id="state"
                                sx={{ "& fieldset": { borderRadius: "14px" } }}
                                options={departments}
                                getOptionLabel={(option) => option.name}
                                loading={loadingDepartments}
                                onChange={(_, newValue) => {
                                    setValue(
                                        "state",
                                        newValue ? newValue.name : "",
                                    );
                                    setCities([]); // Reset cities when department changes
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        error={!!errors.state}
                                        helperText={
                                            get(errors, "state").message
                                        }
                                        InputProps={{
                                            ...params.InputProps,
                                            endAdornment: (
                                                <>
                                                    {loadingDepartments ? (
                                                        <CircularProgress
                                                            color="inherit"
                                                            size={20}
                                                        />
                                                    ) : null}
                                                    {
                                                        params.InputProps
                                                            .endAdornment
                                                    }
                                                </>
                                            ),
                                        }}
                                    />
                                )}
                            />
                        )}
                    />
                </FormControl>
            </Grid>
            <Grid item xs={6}>
                <InputLabel>Ciudad</InputLabel>
                <FormControl fullWidth>
                    <Controller
                        name="city"
                        control={control}
                        render={({ field }) => (
                            <Autocomplete
                                {...field}
                                id="city"
                                sx={{ "& fieldset": { borderRadius: "14px" } }}
                                options={cities}
                                getOptionLabel={(option) => option.name}
                                loading={loadingCities}
                                readOnly={!selectedStateName}
                                onChange={(_, newValue) => {
                                    setValue(
                                        "city",
                                        newValue ? newValue.name : "",
                                    );
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        error={!!errors.city}
                                        helperText={get(errors, "city").message}
                                        InputProps={{
                                            ...params.InputProps,
                                            endAdornment: (
                                                <>
                                                    {loadingCities ? (
                                                        <CircularProgress
                                                            color="inherit"
                                                            size={20}
                                                        />
                                                    ) : null}
                                                    {
                                                        params.InputProps
                                                            .endAdornment
                                                    }
                                                </>
                                            ),
                                        }}
                                    />
                                )}
                            />
                        )}
                    />
                </FormControl>
            </Grid>
        </Grid>
    );
};

export const EVENT_TYPES_TO_NAMES: Record<string, string> = {
    Error: "Error",
    Status: "Estado",
    "Clock Sync": "Sincronización de reloj",
    Occurrence: "Ocurrencia",
    "Remote supply connection": "Conexión remota del suministro",
    "Remote supply disconnection": "Desconexión remota del suministro",
    "Load Profile 1 write channels operation": "Configuracion de canales de escritura del Perfil de Carga 1",
    "Load Profile 2 write channels operation": "Configuracion de canales de escritura del Perfil de Carga 2",
    "Load Profile 1 write capture period operation": "Load Profile 1 write capture period operation",
    "Load Profile 2 write capture period operation": "Load Profile 2 write capture period operation",
    "Tariff change status": "Cambio de canal de tarifa",
    "Outage event": "Evento de corte",
    "Restore event": "Restaurar evento",
};
